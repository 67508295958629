import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersPeopleX: InitialCardPositionLayerProps = {
  CHARACTER_PIONEERS: 0,

  BUILDING_THE_VENTURER: 1,
  BUILDING_COTTAGE: 1,
  BUILDING_RESIDENCE: 1,

  CHARACTER_CARRIER: 2,

  BUILDING_GUILD_HALL: 3,
  BUILDING_MILITARY_ACADEMY: 3,
  BUILDING_ADVENTURERS_GUILD: 3,
  BUILDING_ARCANE_ACADEMY: 3,

  RESOURCE_CHAIN_MAIL: 4,
  RESOURCE_ENHANCED_ROBE: 4,
  RESOURCE_IRON_SHIELD: 4,
  RESOURCE_LIGHT_ARMOR: 4,
  RESOURCE_ROBE: 4,
  RESOURCE_SILVERLINED_ROBE: 4,
  RESOURCE_PADDED_ARMOR: 4,
  RESOURCE_WOODEN_SHIELD: 4,
  RESOURCE_AXE: 4,
  RESOURCE_BACKPACK: 4,
  RESOURCE_CHISEL: 4,
  RESOURCE_HAMMER: 4,
  RESOURCE_HUNTING_BOW: 4,
  RESOURCE_KNIFE: 4,
  RESOURCE_PICKAXE: 4,
  RESOURCE_PLIERS: 4,
  RESOURCE_SAW: 4,
  RESOURCE_SHOVEL: 4,
  RESOURCE_SICKLE: 4,
  RESOURCE_BRONZE_SWORD: 4,
  RESOURCE_MAGIC_BLADE: 4,
  RESOURCE_DIAMOND_STAFF: 4,
  RESOURCE_EMERALD_STAFF: 4,
  RESOURCE_IRON_LANCE: 4,
  RESOURCE_IRON_STAFF: 4,
  RESOURCE_IRON_SWORD: 4,
  RESOURCE_MAGIC_TORCH: 4,
  RESOURCE_RUBY_STAFF: 4,
  RESOURCE_SILVER_BLADE: 4,
  RESOURCE_TORCH: 4,
  RESOURCE_WOODEN_ROD: 4,
  RESOURCE_WOODEN_SPEAR: 4,

  CHARACTER_CONSTRUCTION_SITE_CARRIER: 5,
  CHARACTER_DIGGER: 5,
  CHARACTER_BUILDER: 5,
  CHARACTER_SMITH: 5,
  CHARACTER_SMELTER: 5,
  CHARACTER_MINTER: 5,
  CHARACTER_MINER: 5,
  CHARACTER_FARMER: 5,
  CHARACTER_MILLER: 5,
  CHARACTER_FOOD_WORKER: 5,
  CHARACTER_PROVISIONER: 5,
  CHARACTER_WELL_WORKER: 5,
  CHARACTER_TAILOR: 5,
  CHARACTER_WEAVER: 5,
  CHARACTER_COOK: 5,
  CHARACTER_FOREST_WORKER: 5,
  CHARACTER_HUNTER: 5,
  CHARACTER_FORESTER: 5,
  CHARACTER_FORAGER: 5,
  CHARACTER_WOODWORKER: 5,
  CHARACTER_WOODCUTTER: 5,
  CHARACTER_SAWMILL_WORKER: 5,
  CHARACTER_STONE_MASON: 5,
  CHARACTER_STONEMILL_WORKER: 5,
  CHARACTER_EXPLORER: 5,
  CHARACTER_MERCHANT: 5,
  CHARACTER_TRADE_CARRIER: 5,
  CHARACTER_GUARD: 5,
  CHARACTER_VETERAN_GUARD: 5,
  CHARACTER_SOLDIER: 5,
  CHARACTER_ADVANCED_SOLDIER: 5,
  CHARACTER_VETERAN_SOLDIER: 5,
  CHARACTER_RANGER: 5,
  CHARACTER_SKILLED_RANGER: 5,
  CHARACTER_FEARNAUGHT: 5,
  CHARACTER_HEROIC_FEARNAUGHT: 5,

  BUILDING_GUARD_TOWER: 6,
  BUILDING_GARRISON: 6,
  BUILDING_MILITARY_CAMP: 7,

  BUILDING_EXPLORERS_HUT: 6,
  BUILDING_WOODCUTTING_HUT: 6,
  BUILDING_SAWMILL: 6,
  BUILDING_FORESTER: 6,
  BUILDING_QUARRY: 6,
  BUILDING_STONE_MASON: 6,
  BUILDING_STONE_MILL: 6,
  BUILDING_FORAGERS_HUT: 6,
  BUILDING_HUNTING_LODGE: 6,
  BUILDING_VEGETABLE_FARM: 6,
  BUILDING_CROP_FARM: 6,
  BUILDING_FARMLAND: 6,
  BUILDING_WELL: 6,
  BUILDING_WINDMILL: 6,
  BUILDING_BAKERY: 6,

  BUILDING_PROVISIONER: 6,
  BUILDING_MARKET_FOUNTAIN: 6,
  BUILDING_MARKET_STALL: 6,
  BUILDING_TAVERN: 6,
  BUILDING_TAVERN_TABLE: 6,
  BUILDING_COPPER_HUT: 6,
  BUILDING_COAL_HUT: 6,
  BUILDING_IRON_HUT: 6,
  BUILDING_SILVER_HUT: 6,
  BUILDING_SMELTING_WORKS: 6,
  BUILDING_WEAVING_SHED: 6,
  BUILDING_TAILORS_SHOP: 6,
  BUILDING_MINT: 6,
  BUILDING_TOOL_SMITH: 6,
  BUILDING_WOOD_WORKSHOP: 6,
  BUILDING_WEAPONSMITH: 6,
  BUILDING_ARMORSMITH: 6,
  BUILDING_ARTISAN_BLACKSMITH: 6,
  BUILDING_STORAGE: 6,
  BUILDING_TREASURY: 6,
  BUILDING_TRADING_POST: 6,
  BUILDING_TRADERS_CAMP: 7,
  BUILDING_CONSTRUCTION_CAMP: 7,
};
