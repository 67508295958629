import type {
  InitialCardPositionLayerProps,
  InitialCardPositionProps,
} from "../interfaces";

export const getNodePositions = (
  nodeXLayers: InitialCardPositionLayerProps,
  nodeYLayers: InitialCardPositionLayerProps,
  offsetX: number | undefined = 0,
  offsetY: number | undefined = 0,
): InitialCardPositionProps => {
  const initialCardPositions: InitialCardPositionProps = {};

  Object.keys(nodeXLayers).forEach((name) => {
    let x = -1000;
    let y = Math.floor(Math.random() * 5000) + 1000;

    let xValue = nodeXLayers[name];
    if (typeof xValue !== "undefined") {
      x = (xValue + offsetX) * 750;
    }

    let yValue = nodeYLayers[name];
    if (typeof yValue !== "undefined") {
      y = (yValue + offsetY) * 100;
    }

    initialCardPositions[name] = { x, y };
  });

  return initialCardPositions;
};
