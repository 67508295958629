import { getNodePositions } from "@/lib/utils/getNodePositions";
import { layersPeopleX } from "./layersPeopleX";
import { layersPeopleY } from "./layersPeopleY";
import { layersRegularX } from "./layersRegularX";
import { layersRegularY } from "./layersRegularY";

export const getStaticNodePositions = (view?: string) => {
  switch (view) {
    default:
    case "REGULAR":
      return getNodePositions(layersRegularX, layersRegularY);
    case "PEOPLE":
      return getNodePositions(layersPeopleX, layersPeopleY, 7, -24);
  }
};
