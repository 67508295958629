import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_THE_VENTURER: 0,

  BUILDING_STORAGE: 6,

  BUILDING_WELL: 3,
  RESOURCE_WATER: 3,
  BUILDING_FARMLAND: 4,
  BUILDING_CROP_FARM: 1,
  RESOURCE_WHEAT: 0,
  BUILDING_WINDMILL: 0,
  RESOURCE_FLOUR: 0,
  BUILDING_BAKERY: 1,

  BUILDING_VEGETABLE_FARM: 4,
  RESOURCE_PUMPKIN: 4,
  RESOURCE_CABBAGE: 5,

  BUILDING_FORAGERS_HUT: 5,
  RESOURCE_RASPBERRIES: 6,
  RESOURCE_PORCINI: 7,

  BUILDING_HUNTING_LODGE: 6,
  RESOURCE_RABBIT_MEAT: 8,
  RESOURCE_BOAR_MEAT: 9,
  RESOURCE_DEER_MEAT: 10,
  RESOURCE_LEATHER: 8,

  BUILDING_PROVISIONER: 6,
  RESOURCE_RATION: 6,
  BUILDING_EXPLORERS_HUT: 6,

  BUILDING_MARKET_STALL: 0,
  BUILDING_MARKET_FOUNTAIN: 2,
  BUILDING_TAVERN: 0,
  BUILDING_TAVERN_TABLE: 0,
  RESOURCE_COMFORT_MEAL: 1,
  RESOURCE_NUTRITIOUS_MEAL: 3,

  BUILDING_COTTAGE: 0,
  BUILDING_RESIDENCE: 2,
  CHARACTER_CARRIER: 1,

  BUILDING_FORESTER: 7,
  RESOURCE_SOFTWOOD: 8,
  RESOURCE_HARDWOOD: 9,
  RESOURCE_FIREWOOD: 9,

  BUILDING_WOODCUTTING_HUT: 9,
  BUILDING_SAWMILL: 10,

  RESOURCE_SOFTWOOD_TRUNK: 10,
  RESOURCE_HARDWOOD_TRUNK: 11,
  RESOURCE_SOFTWOOD_BEAM: 10,
  RESOURCE_HARDWOOD_BOARD: 11,

  BUILDING_WEAVING_SHED: 23,
  RESOURCE_ROPE: 23,
  RESOURCE_CLOTH: 24,

  BUILDING_WEAPONSMITH: 15,
  BUILDING_ARMORSMITH: 17,
  BUILDING_WOOD_WORKSHOP: 22,
  BUILDING_TAILORS_SHOP: 25,
  BUILDING_TOOL_SMITH: 32,

  RESOURCE_BRONZE_SWORD: 13,
  RESOURCE_IRON_LANCE: 14,
  RESOURCE_IRON_SWORD: 15,
  RESOURCE_SILVER_BLADE: 16,
  RESOURCE_IRON_SHIELD: 17,
  RESOURCE_CHAIN_MAIL: 18,

  RESOURCE_WOODEN_SPEAR: 19,
  RESOURCE_WOODEN_SHIELD: 20,
  RESOURCE_TORCH: 21,
  RESOURCE_LIGHT_ARMOR: 22,
  RESOURCE_PADDED_ARMOR: 23,
  RESOURCE_WOODEN_COGWHEEL: 25,
  RESOURCE_HUNTING_BOW: 27,

  RESOURCE_BACKPACK: 28,

  RESOURCE_HAMMER: 29,
  RESOURCE_SHOVEL: 30,
  RESOURCE_AXE: 31,
  RESOURCE_PICKAXE: 32,
  RESOURCE_SICKLE: 33,
  RESOURCE_KNIFE: 34,
  RESOURCE_SAW: 35,
  RESOURCE_CHISEL: 36,
  RESOURCE_PLIERS: 37,

  RESOURCE_WOODEN_ROD: 40,
  RESOURCE_IRON_STAFF: 41,

  RESOURCE_BREAD: 2,
  RESOURCE_CORN: 3,
  RESOURCE_FLAX: 3,

  BUILDING_QUARRY: 11,
  RESOURCE_STONE: 10,
  BUILDING_STONE_MASON: 10,

  BUILDING_MILITARY_CAMP: 14,
  BUILDING_GARRISON: 12,
  BUILDING_GUARD_TOWER: 10,
  RESOURCE_CUT_STONE: 10,
  RESOURCE_BORDER_STONE: 11,

  BUILDING_STONE_MILL: 12,
  RESOURCE_LIMESTONE: 12,
  RESOURCE_CEMENT: 12,

  BUILDING_COPPER_HUT: 16,
  RESOURCE_COPPER: 16,

  BUILDING_IRON_HUT: 20,
  RESOURCE_IRON_ORE: 20,

  BUILDING_SILVER_HUT: 24,
  RESOURCE_SILVER_ORE: 24,

  BUILDING_COAL_HUT: 28,
  RESOURCE_COAL: 28,

  BUILDING_SMELTING_WORKS: 22,
  RESOURCE_SILVER_BAR: 25,
  RESOURCE_IRON_BAR: 19,

  BUILDING_ARTISAN_BLACKSMITH: 25,
  BUILDING_ARCANE_ACADEMY: 28,

  RESOURCE_MAGIC_TORCH: 24,
  RESOURCE_MAGIC_BLADE: 25,

  BUILDING_GUILD_HALL: 32,

  CHARACTER_DIGGER: 31,
  CHARACTER_BUILDER: 32,
  CHARACTER_CONSTRUCTION_SITE_CARRIER: 33,
  BUILDING_CONSTRUCTION_CAMP: 31,

  CHARACTER_TRADE_CARRIER: 34,
  BUILDING_TRADING_POST: 34,
  BUILDING_TRADERS_CAMP: 36,

  BUILDING_MILITARY_ACADEMY: 15,
  CHARACTER_GUARD: 14,
  CHARACTER_VETERAN_GUARD: 15,
  CHARACTER_SOLDIER: 16,
  CHARACTER_ADVANCED_SOLDIER: 17,
  CHARACTER_VETERAN_SOLDIER: 18,

  BUILDING_ADVENTURERS_GUILD: 20,
  CHARACTER_RANGER: 19,
  CHARACTER_SKILLED_RANGER: 20,
  CHARACTER_FEARNAUGHT: 21,
  CHARACTER_HEROIC_FEARNAUGHT: 22,

  BUILDING_MINT: 38,
  RESOURCE_SILVER_COINS: 38,
  RESOURCE_COPPER_COINS: 39,
  BUILDING_TREASURY: 40,
  RESOURCE_RUBY: 40,
  RESOURCE_EMERALD: 41,
  RESOURCE_DIAMOND: 42,
  RESOURCE_SINGING_BLADE: 43,
  RESOURCE_ASHWOOD_AEGIS: 44,
  RESOURCE_SICKLE_OF_ABUNDANCE: 45,
  RESOURCE_ANCIENT_COMPASS: 46,
  RESOURCE_GOLDEN_HORSE: 47,
  RESOURCE_WITCHWOOD_HARP: 48,
};
