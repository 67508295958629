import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersPeopleY: InitialCardPositionLayerProps = {
  // x = 0
  CHARACTER_PIONEERS: 44,

  // x = 1
  BUILDING_THE_VENTURER: 43,
  BUILDING_CONSTRUCTION_CAMP: 41,
  BUILDING_COTTAGE: 45,

  // x = 2
  CHARACTER_CARRIER: 44,

  // x = 3
  BUILDING_GUILD_HALL: 32,
  BUILDING_MILITARY_ACADEMY: 55,
  BUILDING_ADVENTURERS_GUILD: 63,

  // x = 4
  RESOURCE_PICKAXE: 14,
  RESOURCE_AXE: 18,
  RESOURCE_HUNTING_BOW: 20,
  RESOURCE_SHOVEL: 24,
  RESOURCE_SICKLE: 27,
  RESOURCE_KNIFE: 32,

  RESOURCE_CHISEL: 38,
  RESOURCE_SAW: 40,
  RESOURCE_HAMMER: 42,
  RESOURCE_PLIERS: 45.5,

  RESOURCE_BACKPACK: 49.5,

  RESOURCE_WOODEN_SPEAR: 53,
  RESOURCE_BRONZE_SWORD: 54,
  RESOURCE_WOODEN_SHIELD: 55,
  RESOURCE_IRON_LANCE: 56,
  RESOURCE_CHAIN_MAIL: 57,
  RESOURCE_IRON_SWORD: 58,
  RESOURCE_IRON_SHIELD: 59,

  RESOURCE_TORCH: 61,
  RESOURCE_MAGIC_TORCH: 62,
  RESOURCE_LIGHT_ARMOR: 63,
  RESOURCE_PADDED_ARMOR: 64,
  RESOURCE_SILVER_BLADE: 65,
  RESOURCE_MAGIC_BLADE: 66,

  // x = 5

  CHARACTER_MINER: 14,
  CHARACTER_WOODCUTTER: 18,
  CHARACTER_HUNTER: 20,
  CHARACTER_FORESTER: 22,
  CHARACTER_DIGGER: 24,
  CHARACTER_FARMER: 26,
  CHARACTER_FORAGER: 28,

  CHARACTER_COOK: 30,
  CHARACTER_PROVISIONER: 32,
  CHARACTER_WEAVER: 34,
  CHARACTER_TAILOR: 36,

  CHARACTER_STONE_MASON: 38,
  CHARACTER_WOODWORKER: 40,
  CHARACTER_BUILDER: 42,
  CHARACTER_SMITH: 44,
  CHARACTER_SMELTER: 47,

  CHARACTER_CONSTRUCTION_SITE_CARRIER: 49,
  CHARACTER_EXPLORER: 50,
  CHARACTER_TRADE_CARRIER: 51,

  CHARACTER_GUARD: 53,
  CHARACTER_VETERAN_GUARD: 54,
  CHARACTER_SOLDIER: 55,
  CHARACTER_ADVANCED_SOLDIER: 56,
  CHARACTER_VETERAN_SOLDIER: 57,

  CHARACTER_RANGER: 62,
  CHARACTER_SKILLED_RANGER: 63,
  CHARACTER_FEARNAUGHT: 64,
  CHARACTER_HEROIC_FEARNAUGHT: 65,

  CHARACTER_WELL_WORKER: 78,
  CHARACTER_FOOD_WORKER: 80,
  CHARACTER_MILLER: 82,
  CHARACTER_SAWMILL_WORKER: 84,
  CHARACTER_STONEMILL_WORKER: 86,
  CHARACTER_MINTER: 88,
  CHARACTER_MERCHANT: 90,
  CHARACTER_FOREST_WORKER: 92,

  // x = 6

  BUILDING_QUARRY: 10,
  BUILDING_COPPER_HUT: 11.5,
  BUILDING_COAL_HUT: 13,
  BUILDING_IRON_HUT: 14.5,
  BUILDING_SILVER_HUT: 16,

  BUILDING_WOODCUTTING_HUT: 18,
  BUILDING_HUNTING_LODGE: 20,
  BUILDING_FORESTER: 22,

  BUILDING_VEGETABLE_FARM: 24.5,
  BUILDING_CROP_FARM: 26,
  BUILDING_FORAGERS_HUT: 28,

  BUILDING_TAVERN: 30,
  BUILDING_PROVISIONER: 32,
  BUILDING_WEAVING_SHED: 34,
  BUILDING_TAILORS_SHOP: 36,

  BUILDING_STONE_MASON: 38,
  BUILDING_WOOD_WORKSHOP: 40,

  BUILDING_TOOL_SMITH: 42,
  BUILDING_WEAPONSMITH: 43.5,
  BUILDING_ARMORSMITH: 45,
  BUILDING_ARTISAN_BLACKSMITH: 46.5,

  BUILDING_SMELTING_WORKS: 48,

  BUILDING_EXPLORERS_HUT: 49.5,
  BUILDING_TRADERS_CAMP: 50.5,
  BUILDING_TRADING_POST: 51.5,

  BUILDING_GUARD_TOWER: 56,
  BUILDING_GARRISON: 58,
  BUILDING_MILITARY_CAMP: 60,

  BUILDING_WELL: 78,
  BUILDING_BAKERY: 80,
  BUILDING_WINDMILL: 82,
  BUILDING_SAWMILL: 84,
  BUILDING_STONE_MILL: 86,
  BUILDING_MINT: 88,
  BUILDING_MARKET_STALL: 90,
};
