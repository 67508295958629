import { availableViews, getViewDependentData } from "./data";
import { enabledLanguages, getDictionary, languages } from "./languages";
import { getNodesAndEdges } from "./nodesAndEdges";

export default {
  availableViews,
  enabledLanguages,
  getDictionary,
  getNodesAndEdges,
  getViewDependentData,
  languages,
};
