import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  BUILDING_SMALL_RESIDENCE: 6,
  BUILDING_MEDIUM_RESIDENCE: 6,
  BUILDING_LARGE_RESIDENCE: 6,
  CHARACTER_CARRIER: 7,

  BUILDING_LOOKOUT_TOWER: 8,
  BUILDING_STORAGE_AREA: 8,
  BUILDING_EYECATCHERS: 8,

  BUILDING_GRAIN_FARM: 8,
  BUILDING_WATERWORKERS_HUT: 8,

  RESOURCE_WATER: 9,
  RESOURCE_GRAIN: 9,

  BUILDING_GRAIN_MILL: 10,
  BUILDING_SHEEP_RANCH: 10,
  BUILDING_PIG_RANCH: 10,
  BUILDING_GOAT_RANCH: 10,
  BUILDING_GOOSE_FARM: 10,
  BUILDING_DONKEY_RANCH: 10,

  CHARACTER_DONKEY: 11,
  BUILDING_MARKETPLACE: 12,

  RESOURCE_FLOUR: 11,
  RESOURCE_SHEEP: 11,
  RESOURCE_PIGS: 11,
  RESOURCE_GOATS: 11,
  RESOURCE_GEESE: 11,

  BUILDING_BAKERY: 12,
  BUILDING_SLAUGHTERHOUSE: 12,
  BUILDING_FISHERMANS_HUT: 12,
  BUILDING_HUNTERS_HUT: 12,

  RESOURCE_BREAD: 13,
  RESOURCE_MEAT: 13,
  RESOURCE_FISH: 13,

  BUILDING_FORESTERS_HUT: 10,
  OTHER_TREES: 11,
  BUILDING_WOODCUTTERS_HUT: 12,
  RESOURCE_LOGS: 13,
  BUILDING_COAL_MAKERS_HUT: 14,
  BUILDING_SAWMILL: 14,
  RESOURCE_PLANKS: 15,

  BUILDING_STONECUTTERS_HUT: 14,
  BUILDING_STONE_MINE: 14,
  BUILDING_COAL_MINE: 14,
  BUILDING_IRON_MINE: 14,
  BUILDING_GOLD_MINE: 14,
  BUILDING_SULFUR_MINE: 14,

  RESOURCE_STONES: 15,
  RESOURCE_COAL: 15,
  RESOURCE_IRON_ORE: 15,
  RESOURCE_GOLD_ORE: 15,
  RESOURCE_SULFUR: 15,

  BUILDING_IRON_SMELTING_WORKS: 16,
  BUILDING_GOLD_SMELTING_WORKS: 16,

  RESOURCE_IRON: 17,
  RESOURCE_GOLD: 17,

  BUILDING_TOOLSMITHS_WORKS: 18,
  BUILDING_WEAPONSMITHS_WORKS: 18,
  BUILDING_SHIPYARD: 18,
  CHARACTER_TRADE_SHIP: 19,
  BUILDING_HARBOR: 20,
  CHARACTER_WARSHIP: 20,

  BUILDING_BEEKEEPERS_HUT: 12,
  BUILDING_AGAVE_FARM: 12,
  BUILDING_SUNFLOWER_FARM: 12,

  RESOURCE_HONEY: 13,
  RESOURCE_AGAVES: 13,
  RESOURCE_SUNFLOWERS: 13,

  BUILDING_VINEYARD: 16,
  BUILDING_MEAD_MAKERS_HUT: 16,
  BUILDING_TEQUILA_MAKERS_WORKS: 16,
  BUILDING_OIL_PRESS: 16,

  RESOURCE_WINE: 17,
  RESOURCE_MEAD: 17,
  RESOURCE_TEQUILA: 17,
  RESOURCE_OIL_CONTAINER: 17,

  BUILDING_AMMO_MAKERS_WORKS: 16,
  BUILDING_GUNPOWDER_MAKERS_WORKS: 16,
  BUILDING_ARROW_FACTORY: 16,
  BUILDING_SMALL_TEMPLE: 18,

  RESOURCE_AMMO: 17,
  RESOURCE_GUNPOWDER: 17,
  RESOURCE_EXPLODING_ARROWS: 17,
  RESOURCE_MANNA: 19,

  BUILDING_VEHICLE_HALL: 18,

  CHARACTER_CART: 20,
  CHARACTER_CATAPULT: 20,
  CHARACTER_THORS_HAMMER: 20,
  CHARACTER_FIRESPITTER: 20,
  CHARACTER_BALISTA: 20,

  OTHER_TOOLS: 19,
  RESOURCE_HAMMER: 19,
  RESOURCE_SHOVEL: 19,
  RESOURCE_PICKAXE: 19,
  RESOURCE_AXE: 19,
  RESOURCE_SAW: 19,
  RESOURCE_FISHING_POLE: 19,
  RESOURCE_SCYTHE: 19,

  RESOURCE_SWORD: 19,
  RESOURCE_BOW: 19,
  RESOURCE_ARMOR: 19,
  RESOURCE_BATTLE_AXE: 19,
  RESOURCE_BLOWGUN: 19,
  RESOURCE_BACKPACK_CATAPULT: 19,

  BUILDING_BARRACKS: 20,
  BUILDING_LARGE_TEMPLE: 20,
  CHARACTER_PRIEST: 21,

  CHARACTER_SWORDSMAN: 21,
  CHARACTER_BOWMAN: 21,
  CHARACTER_SQUAD_LEADER: 21,
  CHARACTER_MEDIC: 21,
  CHARACTER_AXE_WARRIOR: 21,
  CHARACTER_BLOWGUN_WARRIOR: 21,
  CHARACTER_BACKPACK_CATAPULTIST: 21,

  BUILDING_SMALL_TOWER: 22,
  BUILDING_LARGE_TOWER: 22,
  BUILDING_CASTLE: 22,
  BUILDING_HEALERS_QUARTERS: 22,
  CHARACTER_FERRY: 22,
};
