import type {
  CustomEdgeDataProps,
  CustomNodeDataProps,
} from "@/lib/interfaces";
import type { Edge, Node } from "reactflow";
import { getViewDependentData } from "./data";
import { getStaticNodePositions } from "./nodePositions";

export const getNodesAndEdges = (view?: string) => {
  const staticNodePositions = getStaticNodePositions(view);
  const { buildingsData, charactersData, otherData, resourcesData } =
    getViewDependentData(view);

  const staticNodes: Node<CustomNodeDataProps>[] = [];
  const staticEdges: Edge<CustomEdgeDataProps>[] = [];

  switch (view) {
    default: {
      [
        ...buildingsData,
        ...resourcesData,
        ...charactersData.filter((c) => c.name !== "HELPER"),
        ...otherData,
      ].forEach((element) => {
        staticNodes.push({
          id: `${element.type}-${element.name}`,
          position: {
            x: staticNodePositions[`${element.type}_${element.name}`]?.x ?? 0,
            y: staticNodePositions[`${element.type}_${element.name}`]?.y ?? 0,
          },
          data: element,
          type: "pcfNode",
        });
      });

      [...buildingsData, ...charactersData, ...otherData].forEach((element) => {
        // in
        let inResources: string[] = [];
        if (element.distributes)
          inResources = [...inResources, ...element.distributes];
        if (element.handles) inResources = [...inResources, ...element.handles];
        if (element.utilizes)
          inResources = [...inResources, ...element.utilizes];
        if (element.uses) inResources = [...inResources, ...element.uses];
        if (element.resources)
          inResources = [...inResources, ...element.resources];
        inResources.forEach((x) => {
          staticEdges.push({
            id: `RESOURCE-${x}-${element.type}-${element.name}`,
            source: `RESOURCE-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
        element.requires?.forEach((x) => {
          staticEdges.push({
            id: `BUILDING-${x}-${element.type}-${element.name}`,
            source: `BUILDING-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
        element.characters?.forEach((x) => {
          staticEdges.push({
            id: `CHARACTER-${x}-${element.type}-${element.name}`,
            source: `CHARACTER-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
        element.in?.forEach((x) => {
          staticEdges.push({
            id: `${x}-${element.type}-${element.name}`,
            source: `${x}`,
            target: `${element.type}-${element.name}`,
          });
        });

        // out
        let outResources: string[] = [];
        if (element.gathers)
          outResources = [...outResources, ...element.gathers];
        if (element.plants) outResources = [...outResources, ...element.plants];
        if (element.produces)
          outResources = [...outResources, ...element.produces];
        outResources.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-RESOURCE-${x}`,
            source: `${element.type}-${element.name}`,
            target: `RESOURCE-${x}`,
          });
        });
        element.recruits?.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-CHARACTER-${x}`,
            source: `${element.type}-${element.name}`,
            target: `CHARACTER-${x}`,
          });
        });
        element.out?.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-${x}`,
            source: `${element.type}-${element.name}`,
            target: `${x}`,
          });
        });
      });

      break;
    }
  }

  return { staticNodes, staticEdges };
};
