import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_SMALL_RESIDENCE: 10,
  BUILDING_MEDIUM_RESIDENCE: 12,
  BUILDING_LARGE_RESIDENCE: 14,
  CHARACTER_CARRIER: 12,

  BUILDING_STORAGE_AREA: 10,
  BUILDING_LOOKOUT_TOWER: 12,

  BUILDING_WATERWORKS: 6,
  RESOURCE_WATER: 6,

  BUILDING_GRAIN_FARM: 0,
  RESOURCE_GRAIN: 0.5,
  BUILDING_GRAIN_MILL: 0,
  RESOURCE_FLOUR: 0.5,

  BUILDING_PIG_FARM: 2,

  RESOURCE_SHEEP: 2.5,
  RESOURCE_PIGS: 2.5,
  RESOURCE_GOATS: 2.5,
  RESOURCE_GEESE: 2.5,

  BUILDING_DONKEY_FARM: 10,
  CHARACTER_DONKEY: 10.5,
  BUILDING_MARKETPLACE: 8,

  BUILDING_BAKERY: 0,
  RESOURCE_BREAD: 1,
  BUILDING_SLAUGHTERHOUSE: 2,
  RESOURCE_MEAT: 3,
  BUILDING_FISHERMANS_HUT: 6,
  RESOURCE_FISH: 5,

  BUILDING_GOLD_MINE: 0,
  BUILDING_COAL_MINE: 2,
  BUILDING_IRON_ORE_MINE: 4,
  BUILDING_SULFUR_MINE: 6,
  BUILDING_GEM_MINE: 8,
  BUILDING_STONECUTTERS_HUT: 10,

  BUILDING_CHARCOAL_MAKERS_HUT: 12,

  BUILDING_FORESTERS_HUT: 14,
  BUILDING_WOODCUTTERS_HUT: 14,
  BUILDING_SAWMILL: 14,
  OTHER_TREES: 14,
  RESOURCE_LOGS: 14,
  RESOURCE_BOARDS: 14,

  RESOURCE_GOLD_ORE: 0,
  RESOURCE_COAL: 2,
  RESOURCE_IRON_ORE: 4,
  RESOURCE_SULFUR: 6,
  RESOURCE_STONES: 10,
  RESOURCE_GEMS: 8,

  BUILDING_GOLD_SMELTER: -1,
  BUILDING_IRON_SMELTER: 4,
  BUILDING_AMMO_MAKERS_WORKS: 9,
  BUILDING_GUNPOWDER_MAKERS_WORKS: 9,
  BUILDING_GUNPOWDER_MAKER: 9,
  BUILDING_ALCHEMISTS_DEN: 7,

  RESOURCE_GOLD: -1,
  RESOURCE_IRON: 4,
  RESOURCE_GUNPOWDER: 9,

  BUILDING_WEAPON_SMITH: 1,

  BUILDING_CATAPULT_HALL: 4,
  BUILDING_BALLISTA_HALL: 4,
  BUILDING_CANNON_HALL: 4,
  BUILDING_GONG_HALL: 4,

  BUILDING_SHIPYARD: 12,
  BUILDING_LANDING_DOCK: 15,

  CHARACTER_TRADE_SHIP: 14,
  CHARACTER_TRANSPORT_SHIP: 12,

  RESOURCE_CATAPULT_AMMO: 6,
  RESOURCE_BALLISTA_AMMO: 6,
  RESOURCE_CANNON_AMMO: 6,

  CHARACTER_CATAPULT: 6,
  CHARACTER_BALLISTA: 6,
  CHARACTER_CANNON: 6,
  CHARACTER_HOLY_GONG: 6,

  RESOURCE_SWORD: 0,
  RESOURCE_BOW: 1,
  RESOURCE_SPEAR: 2,

  BUILDING_TOOL_SMITH: -6,
  OTHER_TOOLS: -10,
  RESOURCE_HAMMER: -9,
  RESOURCE_SHOVEL: -8,
  RESOURCE_PICKAXE: -7,
  RESOURCE_AXE: -6,
  RESOURCE_SAW: -5,
  RESOURCE_FISHING_POLE: -4,
  RESOURCE_SCYTHE: -3,

  BUILDING_BARRACKS: -2,
  CHARACTER_SWORDSMAN: 0,
  CHARACTER_BOWMAN: 1,
  CHARACTER_SPEARMAN: 2,

  BUILDING_GUARD_TOWER: 0,
  BUILDING_LARGE_GUARD_TOWER: 2,
  BUILDING_CASTLE: 4,
  BUILDING_HEALERS_QUARTERS: 6,

  BUILDING_RICE_FARM: 20,
  BUILDING_BEEKEEPERS_HUT: 20,

  RESOURCE_RICE: 20,
  RESOURCE_HONEY: 20,

  BUILDING_WINERY: 20,
  BUILDING_BREWERY: 20,
  BUILDING_DISTILLERY: 20,
  BUILDING_MEADMAKERS_HUT: 20,

  RESOURCE_WINE: 20,
  RESOURCE_BEER: 20,
  RESOURCE_RICE_WINE: 20,
  RESOURCE_MEAD: 20,

  BUILDING_SMALL_TEMPLE: 20,
  BUILDING_SPHINX: 20,
  RESOURCE_MANNA: 20,

  BUILDING_LARGE_TEMPLE: 22,
  BUILDING_PYRAMID: 22,
  CHARACTER_PRIEST: 20,
};
