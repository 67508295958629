import type {
  CustomEdgeDataProps,
  CustomNodeDataProps,
} from "@/lib/interfaces";
import type { Edge, Node } from "reactflow";
import { getViewDependentData } from "./data";
import { getStaticNodePositions } from "./nodePositions";

export const getNodesAndEdges = (view?: string) => {
  const staticNodePositions = getStaticNodePositions(view);
  const { buildingsData, charactersData, resourcesData } =
    getViewDependentData(view);

  const staticNodes: Node<CustomNodeDataProps>[] = [];
  const staticEdges: Edge<CustomEdgeDataProps>[] = [];

  switch (view) {
    default:
    case "REGULAR": {
      const recruits = charactersData.filter((p) =>
        buildingsData
          .filter((b) => b.recruits)
          .flatMap((b) => b.recruits)
          .includes(p.name),
      );

      [...buildingsData, ...resourcesData, ...recruits].forEach((element) => {
        staticNodes.push({
          id: `${element.type}-${element.name}`,
          position: {
            x: staticNodePositions[`${element.type}_${element.name}`]?.x ?? 0,
            y: staticNodePositions[`${element.type}_${element.name}`]?.y ?? 0,
          },
          data: element,
          type: "pcfNode",
        });
      });

      [...buildingsData].forEach((element) => {
        // in
        let inResources: string[] = [];
        if (element.distributes)
          inResources = [...inResources, ...element.distributes];
        if (element.handles) inResources = [...inResources, ...element.handles];
        if (element.utilizes)
          inResources = [...inResources, ...element.utilizes];
        if (element.uses) inResources = [...inResources, ...element.uses];
        if (element.resources)
          inResources = [...inResources, ...element.resources];
        inResources.forEach((x) => {
          staticEdges.push({
            id: `RESOURCE-${x}-${element.type}-${element.name}`,
            source: `RESOURCE-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
        element.requires?.forEach((x) => {
          staticEdges.push({
            id: `BUILDING-${x}-${element.type}-${element.name}`,
            source: `BUILDING-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
        element.characters
          ?.filter((x) => recruits.some((y) => y.name == x))
          .forEach((x) => {
            staticEdges.push({
              id: `CHARACTER-${x}-${element.type}-${element.name}`,
              source: `CHARACTER-${x}`,
              target: `${element.type}-${element.name}`,
            });
          });

        // out
        let outResources: string[] = [];
        if (element.gathers)
          outResources = [...outResources, ...element.gathers];
        if (element.plants) outResources = [...outResources, ...element.plants];
        if (element.produces)
          outResources = [...outResources, ...element.produces];
        outResources.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-RESOURCE-${x}`,
            source: `${element.type}-${element.name}`,
            target: `RESOURCE-${x}`,
          });
        });
        element.recruits?.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-CHARACTER-${x}`,
            source: `${element.type}-${element.name}`,
            target: `CHARACTER-${x}`,
          });
        });
      });

      break;
    }

    case "PEOPLE": {
      const equipment = resourcesData.filter(
        (p) =>
          p.category === "ARMOR" ||
          p.category === "TOOLS" ||
          p.category === "WEAPONS",
      );

      const buildings = buildingsData.filter((b) => b.characters);

      [...buildings, ...equipment, ...charactersData].forEach((element) => {
        staticNodes.push({
          id: `${element.type}-${element.name}`,
          position: {
            x:
              staticNodePositions[`${element.type}_${element.name}`]?.x ??
              Math.floor(Math.random() * 1000),
            y:
              staticNodePositions[`${element.type}_${element.name}`]?.y ??
              Math.floor(Math.random() * 3000),
          },
          data: element,
          type: "pcfNode",
        });
      });

      [...buildings, ...charactersData].forEach((element) => {
        // in
        let inResources: string[] = [];

        if (element.handles) inResources = [...inResources, ...element.handles];
        if (element.uses) inResources = [...inResources, ...element.uses];
        if (element.type === "CHARACTER" && element.utilizes)
          inResources = [...inResources, ...element.utilizes];
        inResources.forEach((x) => {
          staticEdges.push({
            id: `RESOURCE-${x}-${element.type}-${element.name}`,
            source: `RESOURCE-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });

        element.characters?.forEach((x) => {
          staticEdges.push({
            id: `CHARACTER-${x}-${element.type}-${element.name}`,
            source: `CHARACTER-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });

        // out
        let outResources: string[] = [];
        if (element.distributes)
          outResources = [...outResources, ...element.distributes];

        outResources.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-RESOURCE-${x}`,
            source: `${element.type}-${element.name}`,
            target: `RESOURCE-${x}`,
          });
        });
      });

      break;
    }
  }

  return { staticNodes, staticEdges };
};
