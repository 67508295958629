import type { CustomNodeDataProps } from "@/lib/interfaces";
import rawBuildingsData from "./buildings.json";
import rawCharactersData from "./characters.json";
import rawResourcesData from "./resources.json";

export const availableViews: string[] = ["REGULAR", "PEOPLE"];

export const getViewDependentData = (view?: string) => {
  let buildingsData: CustomNodeDataProps[] = [];
  let charactersData: CustomNodeDataProps[] = [];
  let resourcesData: CustomNodeDataProps[] = [];
  let otherData: CustomNodeDataProps[] = [];

  switch (view) {
    default: {
      buildingsData = rawBuildingsData.map((b) => ({
        type: "BUILDING",
        ...b,
      }));

      charactersData = rawCharactersData.map((c) => ({
        type: "CHARACTER",
        ...c,
      }));

      resourcesData = rawResourcesData.map((r) => ({
        type: "RESOURCE",
        ...r,
      }));

      break;
    }
  }

  return {
    buildingsData,
    charactersData,
    resourcesData,
    otherData,
  };
};
