import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  BUILDING_HEADQUARTERS: 5,
  BUILDING_STOREHOUSE: 5,
  CHARACTER_HELPER: 6,
  BUILDING_FLAG: 7, //

  BUILDING_WELL: 6,
  RESOURCE_WATER: 7,

  RESOURCE_SCYTHE: 6,
  CHARACTER_FARMER: 7,
  BUILDING_FARM: 8,
  RESOURCE_GRAIN: 9,

  CHARACTER_BREWER: 9,
  BUILDING_BREWERY: 10,
  RESOURCE_BEER: 11,

  CHARACTER_MILLER: 9,
  BUILDING_MILL: 10,
  RESOURCE_FLOUR: 11,

  CHARACTER_PIG_BREEDER: 9,
  BUILDING_PIG_FARM: 10,
  RESOURCE_MEAT: 11,

  RESOURCE_ROLLING_PIN: 10,
  CHARACTER_BAKER: 11,
  BUILDING_BAKERY: 12,
  RESOURCE_BREAD: 13,
  CHARACTER_FORESTER: 11,

  RESOURCE_SHOVEL: 10,
  CHARACTER_PLANER: 11,

  RESOURCE_CLEAVER: 10,
  CHARACTER_BUTCHER: 11,
  BUILDING_SLAUGHTERHOUSE: 12,

  RESOURCE_BOW: 10,
  CHARACTER_HUNTER: 11,
  BUILDING_HUNTER: 12,
  CHARACTER_SCOUT: 11,
  BUILDING_LOOKOUT_TOWER: 12,

  RESOURCE_HAM: 13,
  BUILDING_FORESTER: 12,

  RESOURCE_ROD_AND_LINE: 10,
  CHARACTER_FISHER: 11,
  BUILDING_FISHERY: 12,
  RESOURCE_FISH: 13,

  CHARACTER_DONKEY_BREEDER: 9,
  BUILDING_DONKEY_BREEDER: 10,
  CHARACTER_PACK_DONKEY: 11,

  RESOURCE_PICKAXE: 12, //
  RESOURCE_AXE: 12,

  OTHER_TREES: 13,
  CHARACTER_WOODCUTTER: 13,
  CHARACTER_STONEMASON: 13,
  CHARACTER_MINER: 13,

  BUILDING_QUARRY: 14,
  BUILDING_GRANITE_MINE: 14,
  BUILDING_COAL_MINE: 14,
  BUILDING_IRON_MINE: 14,
  BUILDING_GOLD_MINE: 14,
  BUILDING_WOODCUTTER: 14,
  RESOURCE_SAW: 14,
  RESOURCE_CRUCIBLE: 14,

  RESOURCE_STONES: 15,
  RESOURCE_COAL: 15,
  RESOURCE_IRON_ORE: 15,
  RESOURCE_GOLD: 15,
  RESOURCE_WOOD: 15,
  CHARACTER_CARPENTER: 15,
  CHARACTER_IRON_FOUNDER: 15,
  CHARACTER_MINTER: 15,

  BUILDING_CATAPULT: 16,
  BUILDING_IRON_SMELTER: 16,
  BUILDING_MINT: 16,
  BUILDING_SAWMILL: 16,
  RESOURCE_TONGS: 16,
  RESOURCE_HAMMER: 16,

  RESOURCE_IRON: 17,
  RESOURCE_COINS: 17,
  RESOURCE_BOARDS: 17,
  CHARACTER_METALWORKER: 17,
  CHARACTER_ARMORER: 17,
  CHARACTER_SHIPWRIGHT: 17,
  CHARACTER_BUILDER: 17,
  CHARACTER_GEOLOGIST: 17,

  BUILDING_METALWORKS: 18,
  BUILDING_ARMORY: 18,
  BUILDING_SHIPYARD: 18,

  OTHER_TOOLS: 19,
  RESOURCE_SWORD: 19,
  RESOURCE_SHIELD: 19,
  RESOURCE_BOAT: 19,

  CHARACTER_PRIVATE: 20,
  CHARACTER_PRIVATE_FIRST_CLASS: 20,
  CHARACTER_SERGEANT: 20,
  CHARACTER_OFFICER: 20,
  CHARACTER_GENERAL: 20,

  BUILDING_BARRACKS: 21,
  BUILDING_GUARDHOUSE: 21,
  BUILDING_WATCHTOWER: 21,
  BUILDING_FORTRESS: 21,
  BUILDING_HARBOR: 21,
};
