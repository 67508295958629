import { getNodePositions } from "@/lib/utils/getNodePositions";
import { layersRegularX } from "./layersRegularX";
import { layersRegularY } from "./layersRegularY";

export const getStaticNodePositions = (view?: string) => {
  switch (view) {
    default:
      return getNodePositions(layersRegularX, layersRegularY);
  }
};
