import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_RAIN_COLLECTOR: 8,
  BUILDING_ADVANCED_RAIN_COLLECTOR: 10,
  RESOURCE_STORM_WATER: 10,
  RESOURCE_CLEARANCE_WATER: 11,
  RESOURCE_DRIZZLE_WATER: 12,

  BUILDING_STONECUTTERS_CAMP: 14,
  BUILDING_CLAY_PIT: 16,
  BUILDING_HARVESTERS_CAMP: 18,
  BUILDING_PLANTATION: 20,
  BUILDING_HOMESTEAD: 22,
  BUILDING_SMALL_HERBALISTS_CAMP: 24,
  BUILDING_HERBALISTS_CAMP: 26,
  BUILDING_GREENHOUSE: 28,

  BUILDING_FARM_FIELD: 33,

  BUILDING_HERB_GARDEN: 30,
  BUILDING_HALLOWED_HERB_GARDEN: 32,
  BUILDING_SMALL_FARM: 34,
  BUILDING_HALLOWED_SMALL_FARM: 36,
  BUILDING_SMALL_FORAGERS_CAMP: 38,
  BUILDING_FORAGERS_CAMP: 40,
  BUILDING_SMALL_TRAPPERS_CAMP: 42,
  BUILDING_TRAPPERS_CAMP: 44,
  BUILDING_FORESTERS_HUT: 46,
  BUILDING_WOODCUTTERS_CAMP: 48,
  BUILDING_MINE: 50,

  RESOURCE_REED: 17,
  RESOURCE_PLANT_FIBER: 19,
  RESOURCE_BERRIES: 22,
  RESOURCE_MUSHROOMS: 24,
  RESOURCE_HERBS: 26,

  RESOURCE_VEGETABLES: 33,
  RESOURCE_GRAIN: 35,
  RESOURCE_ROOTS: 37,
  RESOURCE_INSECTS: 43,
  RESOURCE_SEA_MARROW: 14,
  RESOURCE_STONE: 15,
  RESOURCE_CLAY: 16,

  RESOURCE_RESIN: 45,
  RESOURCE_WOOD: 48,

  RESOURCE_EGGS: 36,
  RESOURCE_MEAT: 38,
  RESOURCE_LEATHER: 30,

  RESOURCE_COPPER_ORE: 50,

  BUILDING_RANCH: 30,
  BUILDING_STAMPING_MILL: 40,
  BUILDING_TINCTURY: 46,

  BUILDING_BEANERY: 10,
  BUILDING_BRICKYARD: 12,

  BUILDING_BREWERY: 26,
  BUILDING_FLAWLESS_BREWERY: 28,
  BUILDING_GRANARY: 30,
  BUILDING_LEATHERWORKER: 32,
  BUILDING_FLAWLESS_LEATHERWORKER: 34,
  BUILDING_SUPPLIER: 36,

  BUILDING_CELLAR: 14,
  BUILDING_FLAWLESS_CELLAR: 16,
  BUILDING_SMOKEHOUSE: 18,
  BUILDING_KILN: 20,

  BUILDING_BUTCHER: 24,
  BUILDING_FIELD_KITCHEN: 28,
  RESOURCE_FLOUR: 38,

  BUILDING_GRILL: 24,
  BUILDING_FURNACE: 26,
  BUILDING_COOKHOUSE: 28,
  BUILDING_BRICK_OVEN: 30,
  BUILDING_BAKERY: 32,
  RESOURCE_CRYSTALIZED_DEW: 11,

  BUILDING_MANUFACTORY: 14,
  RESOURCE_COPPER_BARS: 19,
  BUILDING_SMELTER: 24,
  BUILDING_FLAWLESS_SMELTER: 26,

  BUILDING_RAINPUNK_FOUNDRY: 8,
  RESOURCE_WILDFIRE_ESSENCE: 8,
  RESOURCE_PARTS: 9,

  BUILDING_FINESMITH: 12,
  BUILDING_TOOLSHOP: 14,
  RESOURCE_PIGMENT: 16,
  BUILDING_CRUDE_WORKSTATION: 18,
  BUILDING_WORKSHOP: 20,
  BUILDING_DISTILLERY: 22,
  BUILDING_PROVISIONER: 24,

  RESOURCE_AMBER: 12,

  BUILDING_ALCHEMISTS_HUT: 20,
  BUILDING_WEAVER: 22,
  BUILDING_APOTHECARY: 24,
  BUILDING_TEAHOUSE: 26,

  RESOURCE_BRICKS: 12,
  RESOURCE_FABRIC: 14,
  RESOURCE_WINE: 16,

  BUILDING_DRUIDS_HUT: 8,
  BUILDING_FLAWLESS_DRUIDS_HUT: 10,
  BUILDING_CLOTHIER: 14,
  BUILDING_COOPERAGE: 18,
  BUILDING_FLAWLESS_COOPERAGE: 20,
  BUILDING_RAIN_MILL: 22,
  BUILDING_FLAWLESS_RAIN_MILL: 24,
  BUILDING_MAKESHIFT_POST: 26,
  BUILDING_TINKERER: 28,
  BUILDING_SCRIBE: 30,
  BUILDING_SMITHY: 32,
  BUILDING_LUMBER_MILL: 34,

  RESOURCE_INCENSE: 14,
  RESOURCE_TRAINING_GEAR: 16,
  RESOURCE_TEA: 18,
  RESOURCE_PACK_OF_CROPS: 20,
  RESOURCE_SCROLLS: 22,
  RESOURCE_PACK_OF_PROVISIONS: 24,
  RESOURCE_PACK_OF_BUILDING_MATERIALS: 26,
  RESOURCE_ALE: 28,
  RESOURCE_PIPES: 30,
  RESOURCE_PACK_OF_TRADE_GOODS: 32,

  BUILDING_ARTISAN: 8,
  BUILDING_PRESS: 16,
  BUILDING_CARPENTER: 24,

  RESOURCE_COATS: 14,
  RESOURCE_PACK_OF_LUXURY_GOODS: 16,
  RESOURCE_TOOLS: 30,

  BUILDING_MONASTERY: 0,
  BUILDING_TAVERN: 2,
  BUILDING_CLAN_HALL: 4,
  BUILDING_EXPLORERS_LODGE: 6,
  BUILDING_FORUM: 8,
  BUILDING_TEMPLE: 10,
  BUILDING_HOLY_TEMPLE: 12,
  BUILDING_TEA_DOCTOR: 14,
  BUILDING_GUILD_HOUSE: 16,
  BUILDING_MARKET: 18,
  BUILDING_HOLY_MARKET: 20,
  BUILDING_BATH_HOUSE: 22,

  RESOURCE_LEISURE: 4,
  RESOURCE_BRAWLING: 6,
  RESOURCE_EDUCATION: 8,
  RESOURCE_RELIGION: 12,
  RESOURCE_LUXURY: 16,
  RESOURCE_TREATMENT: 18,
  RESOURCE_CLEANLINESS: 22,

  BUILDING_SHELTER: 24,
  BUILDING_BIG_SHELTER: 26,
  BUILDING_HUMAN_HOUSE: 28,
  BUILDING_BEAVER_HOUSE: 30,
  BUILDING_LIZARD_HOUSE: 32,
  BUILDING_HARPY_HOUSE: 34,
  BUILDING_FOX_HOUSE: 36,

  RESOURCE_BASIC_HOUSING: 25,
  RESOURCE_HUMAN_HOUSING: 28,
  RESOURCE_BEAVER_HOUSING: 30,
  RESOURCE_LIZARD_HOUSING: 32,
  RESOURCE_HARPY_HOUSING: 34,
  RESOURCE_FOX_HOUSING: 36,

  RESOURCE_JERKY: 17,
  RESOURCE_PORRIDGE: 38,
  RESOURCE_SKEWERS: 40,
  RESOURCE_BISCUITS: 42,
  RESOURCE_PIE: 44,
  RESOURCE_PICKLED_GOODS: 46,

  CHARACTER_HUMAN: 28,
  CHARACTER_BEAVER: 30,
  CHARACTER_LIZARD: 32,
  CHARACTER_HARPY: 34,
  CHARACTER_FOX: 36,

  RESOURCE_PLANKS: 50,
  RESOURCE_COAL: 52,
  RESOURCE_OIL: 54,
  RESOURCE_BARRELS: 56,
  RESOURCE_POTTERY: 58,
  RESOURCE_WATERSKINS: 60,

  RESOURCE_STONE_SMALL: 12,
  RESOURCE_STONE_LARGE: 13,
  RESOURCE_CLAY_SMALL: 14,
  RESOURCE_CLAY_LARGE: 15,
  RESOURCE_SEA_MARROW_SMALL: 16,
  RESOURCE_SEA_MARROW_LARGE: 17,

  RESOURCE_FLAX_SMALL: 18,
  RESOURCE_FLAX_LARGE: 19,
  RESOURCE_REED_SMALL: 20,
  RESOURCE_REED_LARGE: 21,

  RESOURCE_HERBS_SMALL: 22,
  RESOURCE_HERBS_LARGE: 23,
  RESOURCE_DEWBERRY_BUSH_SMALL: 24,
  RESOURCE_DEWBERRY_BUSH_LARGE: 25,
  RESOURCE_MUSHROOM_SMALL: 26,
  RESOURCE_MUSHROOM_LARGE: 27,
  RESOURCE_MARSHLANDS_MUSHROOM_SMALL: 28,
  RESOURCE_MARSHLANDS_MUSHROOM_LARGE: 29,

  RESOURCE_SWAMP_WHEAT_SMALL: 36,
  RESOURCE_SWAMP_WHEAT_LARGE: 37,
  RESOURCE_ROOTS_SMALL: 38,
  RESOURCE_ROOTS_LARGE: 39,
  RESOURCE_MOSS_BROCCOLI_SMALL: 40,
  RESOURCE_MOSS_BROCCOLI_LARGE: 41,

  RESOURCE_SNAIL_BROODMOTHER_SMALL: 42,
  RESOURCE_SNAIL_BROODMOTHER_LARGE: 43,
  RESOURCE_LEECH_BROODMOTHER_SMALL: 44,
  RESOURCE_LEECH_BROODMOTHER_LARGE: 45,
  RESOURCE_WORMTONGUE_NEST_SMALL: 46,
  RESOURCE_WORMTONGUE_NEST_LARGE: 47,
  RESOURCE_SNAKE_NEST_SMALL: 48,
  RESOURCE_SNAKE_NEST_LARGE: 49,
  RESOURCE_STORMBIRD_NEST_SMALL: 50,
  RESOURCE_STORMBIRD_NEST_LARGE: 51,
};
