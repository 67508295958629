import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  BUILDING_MAIN_CASTLE: 5,
  BUILDING_WAREHOUSE: 5,
  CHARACTER_SETTLER: 6,
  BUILDING_FLAG: 7,

  RESOURCE_SCYTHE: 6,
  CHARACTER_FARMER: 7,
  BUILDING_FARM: 8,
  RESOURCE_WHEAT: 9,

  CHARACTER_MILLER: 9,
  BUILDING_WINDMILL: 10,
  RESOURCE_FLOUR: 11,

  CHARACTER_PIG_FARMER: 9,
  BUILDING_PIGSTY: 10,
  RESOURCE_PIGS: 11,

  CHARACTER_BAKER: 11,
  BUILDING_BAKERY: 12,
  RESOURCE_BREAD: 13,
  CHARACTER_FOREST_RANGER: 11,

  RESOURCE_BUTCHERS_KNIFE: 10,
  CHARACTER_BUTCHER: 11,
  BUILDING_BUTCHERS_SHOP: 12,
  RESOURCE_MEAT: 13,
  BUILDING_FOREST_HOUSE: 12,

  RESOURCE_FISHING_POLE: 10,
  CHARACTER_FISHERMAN: 11,
  BUILDING_FISHERMANS_HUT: 12,
  RESOURCE_FISH: 13,

  RESOURCE_PICKAXE: 12,
  RESOURCE_HATCHET: 12,

  OTHER_TREES: 13,
  CHARACTER_LUMBERJACK: 13,
  CHARACTER_QUARRYMAN: 13,
  CHARACTER_MINER: 13,

  BUILDING_QUARRY: 14,
  BUILDING_GRANITE_MINE: 14,
  BUILDING_COAL_MINE: 14,
  BUILDING_IRON_MINE: 14,
  BUILDING_GOLD_MINE: 14,
  BUILDING_LUMBERJACKS_HUT: 14,
  RESOURCE_SAW: 14,

  RESOURCE_STONE: 15,
  RESOURCE_COAL: 15,
  RESOURCE_IRON_ORE: 15,
  RESOURCE_UNREFINED_GOLD: 15,
  RESOURCE_TREE_TRUNKS: 15,
  CHARACTER_CARPENTER: 15,
  CHARACTER_CASTER: 15,

  BUILDING_IRON_FOUNDRY: 16,
  BUILDING_GOLD_FOUNDRY: 16,
  BUILDING_SAWMILL: 16,
  RESOURCE_PLIERS: 16,
  RESOURCE_HAMMER: 16,
  RESOURCE_SHOVEL: 16,

  RESOURCE_IRON: 17,
  RESOURCE_GOLD: 17,
  RESOURCE_WOOD: 17,
  CHARACTER_IRON_WORKER: 17,
  CHARACTER_BLACKSMITH: 17,
  CHARACTER_SHIP_MAKER: 17,
  CHARACTER_CONSTRUCTION_WORKER: 17,
  CHARACTER_GEOLOGIST: 17,
  CHARACTER_LEVELER: 17,

  BUILDING_IRON_WORKER: 18,
  BUILDING_BLACKSMITH: 18,
  BUILDING_SHIP_MAKER: 18,

  OTHER_TOOLS: 19,
  RESOURCE_SWORD: 19,
  RESOURCE_SHIELD: 19,
  RESOURCE_BOAT: 19,

  CHARACTER_FERRYMAN: 20,
  CHARACTER_CAPTAIN: 20,
  CHARACTER_LIEUTENANT: 20,
  CHARACTER_SERGEANT: 20,
  CHARACTER_CORPORAL: 20,
  CHARACTER_PRIVATE: 20,

  BUILDING_GUARD_HUT: 21,
  BUILDING_WATCHTOWER: 21,
  BUILDING_CASTLE: 21,
};
