import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  CHARACTER_HUMAN: 19,
  CHARACTER_BEAVER: 19,
  CHARACTER_LIZARD: 19,
  CHARACTER_HARPY: 19,
  CHARACTER_FOX: 19,

  RESOURCE_LEISURE: 18,
  RESOURCE_BRAWLING: 18,
  RESOURCE_RELIGION: 18,
  RESOURCE_EDUCATION: 18,
  RESOURCE_LUXURY: 18,
  RESOURCE_TREATMENT: 18,
  RESOURCE_CLEANLINESS: 18,

  RESOURCE_BASIC_HOUSING: 18,
  RESOURCE_BEAVER_HOUSING: 18,
  RESOURCE_FOX_HOUSING: 18,
  RESOURCE_HARPY_HOUSING: 18,
  RESOURCE_HUMAN_HOUSING: 18,
  RESOURCE_LIZARD_HOUSING: 18,

  BUILDING_SHELTER: 17,
  BUILDING_BIG_SHELTER: 17,
  BUILDING_BEAVER_HOUSE: 17,
  BUILDING_FOX_HOUSE: 17,
  BUILDING_HARPY_HOUSE: 17,
  BUILDING_HUMAN_HOUSE: 17,
  BUILDING_LIZARD_HOUSE: 17,

  BUILDING_BATH_HOUSE: 17,
  BUILDING_CLAN_HALL: 17,
  BUILDING_EXPLORERS_LODGE: 17,
  BUILDING_FORUM: 17,
  BUILDING_GUILD_HOUSE: 17,
  BUILDING_MARKET: 17,
  BUILDING_HOLY_MARKET: 17,
  BUILDING_MONASTERY: 17,
  BUILDING_TEA_DOCTOR: 17,
  BUILDING_TAVERN: 17,
  BUILDING_TEMPLE: 17,
  BUILDING_HOLY_TEMPLE: 17,

  RESOURCE_SCROLLS: 14,
  RESOURCE_ALE: 14,
  RESOURCE_TEA: 14,
  RESOURCE_TRAINING_GEAR: 14,
  RESOURCE_WINE: 12,
  RESOURCE_INCENSE: 14,

  BUILDING_ALCHEMISTS_HUT: 11,
  BUILDING_APOTHECARY: 11,
  BUILDING_ARTISAN: 15,
  BUILDING_BAKERY: 8,
  BUILDING_BEANERY: 6,
  BUILDING_BREWERY: 6,
  BUILDING_FLAWLESS_BREWERY: 6,
  BUILDING_BRICK_OVEN: 8,
  BUILDING_BRICKYARD: 6,
  BUILDING_BUTCHER: 7,
  BUILDING_CARPENTER: 15,
  BUILDING_CELLAR: 6,
  BUILDING_FLAWLESS_CELLAR: 6,
  BUILDING_CLOTHIER: 13,
  BUILDING_COOKHOUSE: 8,
  BUILDING_COOPERAGE: 13,
  BUILDING_FLAWLESS_COOPERAGE: 13,
  BUILDING_CRUDE_WORKSTATION: 10,
  BUILDING_DISTILLERY: 10,
  BUILDING_DRUIDS_HUT: 13,
  BUILDING_FLAWLESS_DRUIDS_HUT: 13,
  BUILDING_FIELD_KITCHEN: 7,
  BUILDING_FINESMITH: 10,
  BUILDING_FURNACE: 8,
  BUILDING_GRANARY: 6,
  BUILDING_GRILL: 8,
  BUILDING_KILN: 6,
  BUILDING_LEATHERWORKER: 6,
  BUILDING_FLAWLESS_LEATHERWORKER: 6,
  BUILDING_LUMBER_MILL: 13,
  BUILDING_MAKESHIFT_POST: 13,
  BUILDING_MANUFACTORY: 9,
  BUILDING_PRESS: 15,
  BUILDING_PROVISIONER: 10,
  BUILDING_RAIN_MILL: 13,
  BUILDING_FLAWLESS_RAIN_MILL: 13,
  BUILDING_SCRIBE: 13,
  BUILDING_SMELTER: 9,
  BUILDING_FLAWLESS_SMELTER: 9,
  BUILDING_SMITHY: 13,
  BUILDING_SMOKEHOUSE: 6,
  BUILDING_STAMPING_MILL: 4,
  BUILDING_SUPPLIER: 6,
  BUILDING_TEAHOUSE: 11,
  BUILDING_TINCTURY: 4,
  BUILDING_TINKERER: 13,
  BUILDING_TOOLSHOP: 10,
  BUILDING_WORKSHOP: 10,
  BUILDING_WEAVER: 11,

  BUILDING_RAINPUNK_FOUNDRY: 10,
  RESOURCE_WILDFIRE_ESSENCE: 11,
  RESOURCE_PARTS: 11,

  RESOURCE_FABRIC: 12,
  RESOURCE_BRICKS: 12,
  RESOURCE_PIPES: 14,

  RESOURCE_FLOUR: 7,

  RESOURCE_COPPER_BARS: 9,
  RESOURCE_CRYSTALIZED_DEW: 8,
  RESOURCE_PIGMENT: 10,
  RESOURCE_PACK_OF_BUILDING_MATERIALS: 14,
  RESOURCE_PACK_OF_CROPS: 14,
  RESOURCE_PACK_OF_PROVISIONS: 14,
  RESOURCE_PACK_OF_TRADE_GOODS: 14,
  RESOURCE_AMBER: 11,

  RESOURCE_COATS: 16,
  RESOURCE_PACK_OF_LUXURY_GOODS: 16,
  RESOURCE_TOOLS: 16,

  RESOURCE_JERKY: 7,
  RESOURCE_PORRIDGE: 18,
  RESOURCE_SKEWERS: 18,
  RESOURCE_BISCUITS: 18,
  RESOURCE_PIE: 18,
  RESOURCE_PICKLED_GOODS: 18,

  RESOURCE_WOOD: 3,
  RESOURCE_RESIN: 3,
  RESOURCE_CLAY: 3,
  RESOURCE_COPPER_ORE: 3,
  RESOURCE_MEAT: 5,
  RESOURCE_EGGS: 5,
  RESOURCE_LEATHER: 5,

  BUILDING_RANCH: 4,

  RESOURCE_PLANT_FIBER: 3,
  RESOURCE_REED: 3,
  RESOURCE_GRAIN: 3,
  RESOURCE_MUSHROOMS: 3,
  RESOURCE_HERBS: 3,
  RESOURCE_ROOTS: 3,
  RESOURCE_VEGETABLES: 3,
  RESOURCE_INSECTS: 3,
  RESOURCE_BERRIES: 3,
  RESOURCE_SEA_MARROW: 3,
  RESOURCE_STONE: 3,

  BUILDING_FORAGERS_CAMP: 2,
  BUILDING_FORESTERS_HUT: 2,
  BUILDING_HARVESTERS_CAMP: 2,
  BUILDING_HERBALISTS_CAMP: 2,
  BUILDING_MINE: 2,
  BUILDING_SMALL_FORAGERS_CAMP: 2,
  BUILDING_SMALL_HERBALISTS_CAMP: 2,
  BUILDING_SMALL_TRAPPERS_CAMP: 2,
  BUILDING_STONECUTTERS_CAMP: 2,
  BUILDING_TRAPPERS_CAMP: 2,
  BUILDING_WOODCUTTERS_CAMP: 2,

  BUILDING_HALLOWED_HERB_GARDEN: 2,
  BUILDING_HALLOWED_SMALL_FARM: 2,
  BUILDING_HERB_GARDEN: 2,
  BUILDING_HOMESTEAD: 2,
  BUILDING_PLANTATION: 2,
  BUILDING_SMALL_FARM: 2,
  BUILDING_GREENHOUSE: 2,
  BUILDING_CLAY_PIT: 2,

  BUILDING_FARM_FIELD: 1,
  RESOURCE_STORM_WATER: 1,
  RESOURCE_CLEARANCE_WATER: 1,
  RESOURCE_DRIZZLE_WATER: 1,

  BUILDING_RAIN_COLLECTOR: 0,
  BUILDING_ADVANCED_RAIN_COLLECTOR: 0,

  RESOURCE_PLANKS: 7,
  RESOURCE_COAL: 3,
  RESOURCE_OIL: 3,
  RESOURCE_BARRELS: 3,
  RESOURCE_POTTERY: 3,
  RESOURCE_WATERSKINS: 3,

  RESOURCE_STONE_SMALL: 0,
  RESOURCE_STONE_LARGE: 0,
  RESOURCE_CLAY_SMALL: 0,
  RESOURCE_CLAY_LARGE: 0,
  RESOURCE_SEA_MARROW_SMALL: 0,
  RESOURCE_SEA_MARROW_LARGE: 0,

  RESOURCE_FLAX_SMALL: 0,
  RESOURCE_FLAX_LARGE: 0,
  RESOURCE_REED_SMALL: 0,
  RESOURCE_REED_LARGE: 0,

  RESOURCE_HERBS_SMALL: 0,
  RESOURCE_HERBS_LARGE: 0,
  RESOURCE_DEWBERRY_BUSH_SMALL: 0,
  RESOURCE_DEWBERRY_BUSH_LARGE: 0,
  RESOURCE_MUSHROOM_SMALL: 0,
  RESOURCE_MUSHROOM_LARGE: 0,
  RESOURCE_MARSHLANDS_MUSHROOM_SMALL: 0,
  RESOURCE_MARSHLANDS_MUSHROOM_LARGE: 0,

  RESOURCE_SWAMP_WHEAT_SMALL: 0,
  RESOURCE_SWAMP_WHEAT_LARGE: 0,
  RESOURCE_ROOTS_SMALL: 0,
  RESOURCE_ROOTS_LARGE: 0,
  RESOURCE_MOSS_BROCCOLI_SMALL: 0,
  RESOURCE_MOSS_BROCCOLI_LARGE: 0,

  RESOURCE_SNAIL_BROODMOTHER_SMALL: 0,
  RESOURCE_SNAIL_BROODMOTHER_LARGE: 0,
  RESOURCE_LEECH_BROODMOTHER_SMALL: 0,
  RESOURCE_LEECH_BROODMOTHER_LARGE: 0,
  RESOURCE_WORMTONGUE_NEST_SMALL: 0,
  RESOURCE_WORMTONGUE_NEST_LARGE: 0,
  RESOURCE_SNAKE_NEST_SMALL: 0,
  RESOURCE_SNAKE_NEST_LARGE: 0,
  RESOURCE_STORMBIRD_NEST_SMALL: 0,
  RESOURCE_STORMBIRD_NEST_LARGE: 0,
};
