import DE from "../locales/de-DE.json";
import EN from "../locales/en-US.json";

export const getDictionary = (locale: string) => {
  switch (locale) {
    case "en":
    default:
      return EN;
    case "de":
      return DE;
  }
};

export const languages = ["en-US", "de-DE", "fr-FR"];

export const enabledLanguages = ["en-US", "de-DE"];
