import type { CustomNodeDataProps } from "@/lib/interfaces";
import rawBuildingsData from "./buildings.json";
import rawCharactersData from "./characters.json";
import rawOtherData from "./other.json";
import rawResourcesData from "./resources.json";

export const availableViews: string[] = [
  "ROMANS",
  "VIKINGS",
  "MAYANS",
  "TROJANS",
];

export const getViewDependentData = (view?: string) => {
  let buildingsData: CustomNodeDataProps[] = [];
  let charactersData: CustomNodeDataProps[] = [];
  let resourcesData: CustomNodeDataProps[] = [];
  const otherData: CustomNodeDataProps[] = rawOtherData.map((r) => ({
    type: "OTHER",
    ...r,
  }));

  switch (view) {
    default:
    case "ROMANS": {
      buildingsData = rawBuildingsData["ROMANS"].map((b) => ({
        type: "BUILDING",
        faction: "ROMANS",
        ...b,
      }));

      charactersData = rawCharactersData["ROMANS"].map((c) => ({
        type: "CHARACTER",
        faction: "ROMANS",
        ...c,
      }));

      resourcesData = rawResourcesData["ROMANS"].map((r) => ({
        type: "RESOURCE",
        faction: "ROMANS",
        ...r,
      }));
      break;
    }
    case "VIKINGS": {
      buildingsData = rawBuildingsData["VIKINGS"].map((b) => ({
        type: "BUILDING",
        faction: "VIKINGS",
        ...b,
      }));

      charactersData = rawCharactersData["VIKINGS"].map((c) => ({
        type: "CHARACTER",
        faction: "VIKINGS",
        ...c,
      }));

      resourcesData = rawResourcesData["VIKINGS"].map((r) => ({
        type: "RESOURCE",
        faction: "VIKINGS",
        ...r,
      }));
      break;
    }
    case "MAYANS": {
      buildingsData = rawBuildingsData["MAYANS"].map((b) => ({
        type: "BUILDING",
        faction: "MAYANS",
        ...b,
      }));

      charactersData = rawCharactersData["MAYANS"].map((c) => ({
        type: "CHARACTER",
        faction: "MAYANS",
        ...c,
      }));

      resourcesData = rawResourcesData["MAYANS"].map((r) => ({
        type: "RESOURCE",
        faction: "MAYANS",
        ...r,
      }));
      break;
    }
    case "TROJANS": {
      buildingsData = rawBuildingsData["TROJANS"].map((b) => ({
        type: "BUILDING",
        faction: "TROJANS",
        ...b,
      }));

      charactersData = rawCharactersData["TROJANS"].map((c) => ({
        type: "CHARACTER",
        faction: "TROJANS",
        ...c,
      }));

      resourcesData = rawResourcesData["TROJANS"].map((r) => ({
        type: "RESOURCE",
        faction: "TROJANS",
        ...r,
      }));
      break;
    }
  }

  return {
    buildingsData,
    charactersData,
    resourcesData,
    otherData,
  };
};
