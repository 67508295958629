import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_MAIN_CASTLE: 4,
  BUILDING_WAREHOUSE: 6,
  CHARACTER_SETTLER: 5,
  BUILDING_FLAG: 5,

  RESOURCE_SCYTHE: 1,
  CHARACTER_FARMER: 1,
  BUILDING_FARM: 1,
  RESOURCE_WHEAT: 1,

  CHARACTER_MILLER: 0,
  BUILDING_WINDMILL: 0,
  RESOURCE_FLOUR: 0,

  CHARACTER_PIG_FARMER: 2,
  BUILDING_PIGSTY: 2,
  RESOURCE_PIGS: 3,

  CHARACTER_BAKER: 1,
  BUILDING_BAKERY: 0,
  RESOURCE_BREAD: 0.5,

  RESOURCE_BUTCHERS_KNIFE: 4,
  CHARACTER_BUTCHER: 4,
  BUILDING_BUTCHERS_SHOP: 3,
  RESOURCE_MEAT: 3.5,

  RESOURCE_FISHING_POLE: 6,
  CHARACTER_FISHERMAN: 6,
  BUILDING_FISHERMANS_HUT: 6,
  RESOURCE_FISH: 6.5,

  RESOURCE_PICKAXE: 10,
  CHARACTER_MINER: 9.5,
  CHARACTER_QUARRYMAN: 10.5,

  BUILDING_GOLD_MINE: 2,
  BUILDING_IRON_MINE: 4,
  BUILDING_COAL_MINE: 6,
  BUILDING_GRANITE_MINE: 8,
  BUILDING_QUARRY: 10,

  CHARACTER_CASTER: 3.5,

  RESOURCE_UNREFINED_GOLD: 2,
  RESOURCE_IRON_ORE: 5,
  RESOURCE_COAL: 7,
  RESOURCE_STONE: 9.5,

  BUILDING_GOLD_FOUNDRY: 2,
  BUILDING_IRON_FOUNDRY: 4,

  RESOURCE_GOLD: 2,
  RESOURCE_IRON: 4,
  RESOURCE_PLIERS: 8,
  CHARACTER_BLACKSMITH: 8,
  BUILDING_BLACKSMITH: 7,
  RESOURCE_SWORD: 6,
  RESOURCE_SHIELD: 8,

  CHARACTER_CAPTAIN: 5,
  CHARACTER_LIEUTENANT: 6,
  CHARACTER_SERGEANT: 7,
  CHARACTER_CORPORAL: 8,
  CHARACTER_PRIVATE: 9,

  BUILDING_GUARD_HUT: 0,
  BUILDING_WATCHTOWER: 2,
  BUILDING_CASTLE: 4,

  CHARACTER_IRON_WORKER: 10,
  BUILDING_IRON_WORKER: 10,
  OTHER_TOOLS: 10.5,

  CHARACTER_FOREST_RANGER: 12,
  BUILDING_FOREST_HOUSE: 12,

  OTHER_TREES: 13,
  RESOURCE_HATCHET: 15,
  CHARACTER_LUMBERJACK: 15,
  BUILDING_LUMBERJACKS_HUT: 14,
  RESOURCE_TREE_TRUNKS: 14,

  RESOURCE_SAW: 12,
  CHARACTER_CARPENTER: 12.5,
  BUILDING_SAWMILL: 12,
  RESOURCE_WOOD: 11.5,

  CHARACTER_SHIP_MAKER: 14,
  BUILDING_SHIP_MAKER: 13,
  RESOURCE_BOAT: 13,
  CHARACTER_FERRYMAN: 13,

  RESOURCE_HAMMER: 16,
  CHARACTER_GEOLOGIST: 15,
  CHARACTER_CONSTRUCTION_WORKER: 16,

  RESOURCE_SHOVEL: 17,
  CHARACTER_LEVELER: 17,
};
