import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  BUILDING_SMALL_RESIDENCE: 6,
  BUILDING_MEDIUM_RESIDENCE: 6,
  BUILDING_LARGE_RESIDENCE: 6,
  CHARACTER_CARRIER: 7,

  BUILDING_LOOKOUT_TOWER: 8,
  BUILDING_STORAGE_AREA: 8,

  BUILDING_GRAIN_FARM: 8,
  BUILDING_WATERWORKS: 8,

  RESOURCE_WATER: 9,
  RESOURCE_GRAIN: 9,

  BUILDING_GRAIN_MILL: 10,
  BUILDING_PIG_FARM: 10,
  BUILDING_DONKEY_FARM: 10,

  CHARACTER_DONKEY: 11,
  BUILDING_MARKETPLACE: 12,

  RESOURCE_FLOUR: 11,
  RESOURCE_SHEEP: 11,
  RESOURCE_PIGS: 11,
  RESOURCE_GOATS: 11,
  RESOURCE_GEESE: 11,

  BUILDING_BAKERY: 12,
  BUILDING_SLAUGHTERHOUSE: 12,
  BUILDING_FISHERMANS_HUT: 12,

  RESOURCE_BREAD: 13,
  RESOURCE_MEAT: 13,
  RESOURCE_FISH: 13,

  BUILDING_FORESTERS_HUT: 8,
  OTHER_TREES: 9,
  BUILDING_WOODCUTTERS_HUT: 10,
  RESOURCE_LOGS: 11,
  BUILDING_CHARCOAL_MAKERS_HUT: 14,
  BUILDING_SAWMILL: 12,
  RESOURCE_BOARDS: 13,

  BUILDING_STONECUTTERS_HUT: 14,
  BUILDING_COAL_MINE: 14,
  BUILDING_IRON_ORE_MINE: 14,
  BUILDING_GOLD_MINE: 14,
  BUILDING_SULFUR_MINE: 14,
  BUILDING_GEM_MINE: 14,

  RESOURCE_STONES: 15,
  RESOURCE_COAL: 15,
  RESOURCE_IRON_ORE: 15,
  RESOURCE_GOLD_ORE: 15,
  RESOURCE_SULFUR: 15,
  RESOURCE_GEMS: 15,

  BUILDING_IRON_SMELTER: 16,
  BUILDING_GOLD_SMELTER: 16,
  BUILDING_ALCHEMISTS_DEN: 16,
  BUILDING_GUNPOWDER_MAKER: 16,

  RESOURCE_GUNPOWDER: 17,

  RESOURCE_IRON: 17,
  RESOURCE_GOLD: 17,

  BUILDING_TOOL_SMITH: 18,
  BUILDING_WEAPON_SMITH: 18,
  BUILDING_SHIPYARD: 18,

  CHARACTER_TRADE_SHIP: 19,
  BUILDING_LANDING_DOCK: 20,

  BUILDING_RICE_FARM: 12,
  BUILDING_BEEKEEPERS_HUT: 12,

  RESOURCE_RICE: 13,
  RESOURCE_HONEY: 13,

  BUILDING_WINERY: 16,
  BUILDING_BREWERY: 16,
  BUILDING_DISTILLERY: 16,
  BUILDING_MEADMAKERS_HUT: 16,

  RESOURCE_WINE: 17,
  RESOURCE_BEER: 17,
  RESOURCE_RICE_WINE: 17,
  RESOURCE_MEAD: 17,

  BUILDING_SMALL_TEMPLE: 18,
  BUILDING_SPHINX: 18,

  RESOURCE_MANNA: 19,

  BUILDING_CATAPULT_HALL: 18,
  BUILDING_BALLISTA_HALL: 18,
  BUILDING_CANNON_HALL: 18,
  BUILDING_GONG_HALL: 18,

  RESOURCE_CATAPULT_AMMO: 19,
  RESOURCE_BALLISTA_AMMO: 19,
  RESOURCE_CANNON_AMMO: 19,

  CHARACTER_CATAPULT: 20,
  CHARACTER_BALLISTA: 20,
  CHARACTER_CANNON: 20,
  CHARACTER_HOLY_GONG: 20,

  OTHER_TOOLS: 19,
  RESOURCE_HAMMER: 19,
  RESOURCE_SHOVEL: 19,
  RESOURCE_PICKAXE: 19,
  RESOURCE_AXE: 19,
  RESOURCE_SAW: 19,
  RESOURCE_FISHING_POLE: 19,
  RESOURCE_SCYTHE: 19,

  RESOURCE_SWORD: 19,
  RESOURCE_BOW: 19,
  RESOURCE_SPEAR: 19,

  BUILDING_BARRACKS: 20,
  BUILDING_LARGE_TEMPLE: 20,
  BUILDING_PYRAMID: 20,
  CHARACTER_PRIEST: 21,

  CHARACTER_SWORDSMAN: 21,
  CHARACTER_BOWMAN: 21,
  CHARACTER_SPEARMAN: 21,

  BUILDING_GUARD_TOWER: 22,
  BUILDING_LARGE_GUARD_TOWER: 22,
  BUILDING_CASTLE: 22,
  BUILDING_HEALERS_QUARTERS: 22,
  CHARACTER_TRANSPORT_SHIP: 22,
};
