import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_HEADQUARTERS: 4,
  BUILDING_STOREHOUSE: 6,
  CHARACTER_HELPER: 5,
  BUILDING_FLAG: 5, //

  BUILDING_WELL: 5,
  RESOURCE_WATER: 5,

  RESOURCE_SCYTHE: 1,
  CHARACTER_FARMER: 1,
  BUILDING_FARM: 1,
  RESOURCE_GRAIN: 1,

  CHARACTER_BREWER: 14,
  BUILDING_BREWERY: 14,
  RESOURCE_BEER: 14,

  CHARACTER_MILLER: 0,
  BUILDING_MILL: -0.5,
  RESOURCE_FLOUR: 0,

  CHARACTER_PIG_BREEDER: 2,
  BUILDING_PIG_FARM: 2.5,
  RESOURCE_MEAT: 3,

  RESOURCE_ROLLING_PIN: 1,
  CHARACTER_BAKER: 1,
  BUILDING_BAKERY: 0,
  RESOURCE_BREAD: 0.5,

  RESOURCE_CLEAVER: 4,
  CHARACTER_BUTCHER: 4,
  BUILDING_SLAUGHTERHOUSE: 3,

  RESOURCE_BOW: 5,
  CHARACTER_HUNTER: 5,
  BUILDING_HUNTER: 5,
  CHARACTER_SCOUT: 17,
  BUILDING_LOOKOUT_TOWER: 17,
  RESOURCE_HAM: 3.5,

  RESOURCE_ROD_AND_LINE: 7,
  CHARACTER_FISHER: 7,
  BUILDING_FISHERY: 7,
  RESOURCE_FISH: 6.5,

  CHARACTER_DONKEY_BREEDER: 9.5,
  BUILDING_DONKEY_BREEDER: 9,
  CHARACTER_PACK_DONKEY: 9,

  RESOURCE_PICKAXE: 10,
  CHARACTER_MINER: 9.5,
  CHARACTER_STONEMASON: 10.5,

  BUILDING_GOLD_MINE: 2,
  BUILDING_IRON_MINE: 4,
  BUILDING_COAL_MINE: 6,
  BUILDING_GRANITE_MINE: 8,
  BUILDING_QUARRY: 10,

  RESOURCE_CRUCIBLE: 0,
  CHARACTER_MINTER: 1,
  RESOURCE_GOLD: 2,

  CHARACTER_IRON_FOUNDER: 4,
  RESOURCE_IRON_ORE: 5,
  RESOURCE_COAL: 7,

  RESOURCE_STONES: 9.5,
  BUILDING_CATAPULT: 8,

  BUILDING_MINT: 2,
  BUILDING_IRON_SMELTER: 4,

  RESOURCE_COINS: 2,
  RESOURCE_IRON: 4,
  CHARACTER_ARMORER: 8,
  BUILDING_ARMORY: 7,
  RESOURCE_SWORD: 6,
  RESOURCE_SHIELD: 8,

  CHARACTER_PRIVATE: 5,
  CHARACTER_PRIVATE_FIRST_CLASS: 6,
  CHARACTER_SERGEANT: 7,
  CHARACTER_OFFICER: 8,
  CHARACTER_GENERAL: 9,

  BUILDING_BARRACKS: 2,
  BUILDING_GUARDHOUSE: 4,
  BUILDING_WATCHTOWER: 6,
  BUILDING_FORTRESS: 8,
  BUILDING_HARBOR: 10,

  RESOURCE_TONGS: 10,
  CHARACTER_METALWORKER: 10,
  BUILDING_METALWORKS: 10,
  OTHER_TOOLS: 10.5,

  RESOURCE_SHOVEL: 11,
  CHARACTER_PLANER: 11,
  CHARACTER_FORESTER: 12,
  BUILDING_FORESTER: 12,

  OTHER_TREES: 13,
  RESOURCE_AXE: 15,
  CHARACTER_WOODCUTTER: 15,
  BUILDING_WOODCUTTER: 14,
  RESOURCE_WOOD: 14,

  RESOURCE_SAW: 12,
  CHARACTER_CARPENTER: 12.5,
  BUILDING_SAWMILL: 12,
  RESOURCE_BOARDS: 11.5,

  CHARACTER_SHIPWRIGHT: 14,
  BUILDING_SHIPYARD: 13,
  RESOURCE_BOAT: 13,

  RESOURCE_HAMMER: 16,
  CHARACTER_GEOLOGIST: 15,
  CHARACTER_BUILDER: 16,
};
