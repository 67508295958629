import EN from "../locales/en-US.json";

export const getDictionary = (locale: string) => {
  switch (locale) {
    case "en":
    default:
      return EN;
  }
};

export const languages = [
  "en-US",
  "de-DE",
  "es-ES",
  "fr-FR",
  "pt-BR",
  "it-IT",
  "zh-CN",
  "zh-HK",
  "ko-KR",
  "ja-JP",
  "th-TH",
  "cs-CZ",
  "pl-PL",
  "uk-UA",
  "ru-RU",
  "hu-HU",
  "tr-TR",
];

export const enabledLanguages = ["en-US"];
