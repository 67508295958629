import type { CustomNodeDataProps } from "@/lib/interfaces";
import rawBuildingsCampsData from "./buildings-camps.json";
// import rawBuildingsDecorationData from "./buildings-decoration.json";
import rawBuildingsFoodProductionData from "./buildings-food-production.json";
// import rawBuildingsGeneralData from "./buildings-general.json";
import rawBuildingsHousingData from "./buildings-housing.json";
import rawBuildingsProductionData from "./buildings-production.json";
// import rawBuildingsRoadData from "./buildings-road.json";
import rawBuildingsServiceData from "./buildings-service.json";
import rawCharactersData from "./characters.json";
import rawResourcesData from "./resources.json";

export const availableViews: string[] = [];

export const getViewDependentData = (view?: string) => {
  let buildingsData: CustomNodeDataProps[] = [];
  let charactersData: CustomNodeDataProps[] = [];
  let resourcesData: CustomNodeDataProps[] = [];
  let otherData: CustomNodeDataProps[] = [];

  let rawBuildingsData = [
    ...rawBuildingsCampsData,
    // ...rawBuildingsDecorationData,
    ...rawBuildingsFoodProductionData,
    // ...rawBuildingsGeneralData,
    ...rawBuildingsHousingData,
    // ...rawBuildingsRoadData,
    ...rawBuildingsServiceData,
    ...rawBuildingsProductionData,
  ];
  switch (view) {
    default: {
      buildingsData = rawBuildingsData.map((b) => ({
        type: "BUILDING",
        ...b,
      }));

      charactersData = rawCharactersData.map((c) => ({
        type: "CHARACTER",
        ...c,
      }));

      resourcesData = rawResourcesData.map((r) => ({
        type: "RESOURCE",
        ...r,
      }));

      break;
    }
  }

  return {
    buildingsData,
    charactersData,
    resourcesData,
    otherData,
  };
};
