import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularY: InitialCardPositionLayerProps = {
  BUILDING_SMALL_RESIDENCE: 10,
  BUILDING_MEDIUM_RESIDENCE: 12,
  BUILDING_LARGE_RESIDENCE: 14,
  CHARACTER_CARRIER: 12,

  BUILDING_STORAGE_AREA: 10,
  BUILDING_LOOKOUT_TOWER: 12,
  BUILDING_EYECATCHERS: 14,

  BUILDING_WATERWORKERS_HUT: 6,
  RESOURCE_WATER: 6,

  BUILDING_GRAIN_FARM: 0,
  RESOURCE_GRAIN: 0.5,
  BUILDING_GRAIN_MILL: 0,
  RESOURCE_FLOUR: 0.5,

  BUILDING_SHEEP_RANCH: 2,
  BUILDING_PIG_RANCH: 2,
  BUILDING_GOAT_RANCH: 2,
  BUILDING_GOOSE_FARM: 2,

  RESOURCE_SHEEP: 2.5,
  RESOURCE_PIGS: 2.5,
  RESOURCE_GOATS: 2.5,
  RESOURCE_GEESE: 2.5,

  BUILDING_DONKEY_RANCH: 10,
  CHARACTER_DONKEY: 10.5,
  BUILDING_MARKETPLACE: 8,

  BUILDING_BAKERY: 0,
  RESOURCE_BREAD: 1,
  BUILDING_SLAUGHTERHOUSE: 2,
  BUILDING_HUNTERS_HUT: 4,
  RESOURCE_MEAT: 3,
  BUILDING_FISHERMANS_HUT: 6,
  RESOURCE_FISH: 5,

  BUILDING_GOLD_MINE: 0,
  BUILDING_COAL_MINE: 2,
  BUILDING_IRON_MINE: 4,
  BUILDING_SULFUR_MINE: 6,
  BUILDING_STONE_MINE: 8,
  BUILDING_STONECUTTERS_HUT: 10,

  BUILDING_COAL_MAKERS_HUT: 12,

  BUILDING_FORESTERS_HUT: 14,
  BUILDING_WOODCUTTERS_HUT: 14,
  BUILDING_SAWMILL: 14,
  OTHER_TREES: 14,
  RESOURCE_LOGS: 14,
  RESOURCE_PLANKS: 14,

  RESOURCE_GOLD_ORE: 0,
  RESOURCE_COAL: 2,
  RESOURCE_IRON_ORE: 4,
  RESOURCE_SULFUR: 6,
  RESOURCE_STONES: 10,

  BUILDING_GOLD_SMELTING_WORKS: -1,
  BUILDING_IRON_SMELTING_WORKS: 4,
  BUILDING_AMMO_MAKERS_WORKS: 9,
  BUILDING_GUNPOWDER_MAKERS_WORKS: 9,
  BUILDING_ARROW_FACTORY: 9,

  RESOURCE_GOLD: -1,
  RESOURCE_IRON: 4,
  RESOURCE_AMMO: 9,
  RESOURCE_GUNPOWDER: 9,
  RESOURCE_EXPLODING_ARROWS: 9,

  BUILDING_WEAPONSMITHS_WORKS: 1,
  BUILDING_VEHICLE_HALL: 4,
  BUILDING_SHIPYARD: 12,
  BUILDING_HARBOR: 15,

  CHARACTER_WARSHIP: 10,
  CHARACTER_TRADE_SHIP: 14,
  CHARACTER_FERRY: 12,

  CHARACTER_CART: 6,
  CHARACTER_CATAPULT: 8,
  CHARACTER_THORS_HAMMER: 8,
  CHARACTER_FIRESPITTER: 8,
  CHARACTER_BALISTA: 8,

  RESOURCE_SWORD: 0,
  RESOURCE_BOW: 1,
  RESOURCE_ARMOR: 2,
  RESOURCE_BATTLE_AXE: 3,
  RESOURCE_BLOWGUN: 3,
  RESOURCE_BACKPACK_CATAPULT: 3,

  BUILDING_TOOLSMITHS_WORKS: -6,
  OTHER_TOOLS: -10,
  RESOURCE_HAMMER: -9,
  RESOURCE_SHOVEL: -8,
  RESOURCE_PICKAXE: -7,
  RESOURCE_AXE: -6,
  RESOURCE_SAW: -5,
  RESOURCE_FISHING_POLE: -4,
  RESOURCE_SCYTHE: -3,

  BUILDING_BARRACKS: -2,
  CHARACTER_SWORDSMAN: 0,
  CHARACTER_BOWMAN: 1,
  CHARACTER_SQUAD_LEADER: 2,
  CHARACTER_MEDIC: 3,
  CHARACTER_AXE_WARRIOR: 3,
  CHARACTER_BLOWGUN_WARRIOR: 3,
  CHARACTER_BACKPACK_CATAPULTIST: 3,

  BUILDING_SMALL_TOWER: 0,
  BUILDING_LARGE_TOWER: 2,
  BUILDING_CASTLE: 4,
  BUILDING_HEALERS_QUARTERS: 6,

  BUILDING_BEEKEEPERS_HUT: 20,
  BUILDING_AGAVE_FARM: 20,
  BUILDING_SUNFLOWER_FARM: 20,

  RESOURCE_HONEY: 20,
  RESOURCE_AGAVES: 20,
  RESOURCE_SUNFLOWERS: 20,

  BUILDING_VINEYARD: 20,
  BUILDING_MEAD_MAKERS_HUT: 20,
  BUILDING_TEQUILA_MAKERS_WORKS: 20,
  BUILDING_OIL_PRESS: 20,

  RESOURCE_WINE: 20,
  RESOURCE_MEAD: 20,
  RESOURCE_TEQUILA: 20,
  RESOURCE_OIL_CONTAINER: 20,

  BUILDING_SMALL_TEMPLE: 20,
  RESOURCE_MANNA: 20,

  BUILDING_LARGE_TEMPLE: 22,
  CHARACTER_PRIEST: 20,
};
