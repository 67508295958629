import type { CustomNodeDataProps } from "@/lib/interfaces";
import rawBuildingsData from "./buildings.json";
import rawCharactersData from "./characters.json";
import rawOtherData from "./other.json";
import rawResourcesData from "./resources.json";

export const availableViews: string[] = [
  "ROMANS",
  "EGYPTIANS",
  "ASIANS",
  "AMAZONS",
];

export const getViewDependentData = (view?: string) => {
  let buildingsData: CustomNodeDataProps[] = [];
  let charactersData: CustomNodeDataProps[] = [];
  let resourcesData: CustomNodeDataProps[] = [];
  const otherData: CustomNodeDataProps[] = rawOtherData.map((r) => ({
    type: "OTHER",
    ...r,
  }));

  switch (view) {
    default:
    case "ROMANS": {
      buildingsData = rawBuildingsData["ROMANS"].map((b) => ({
        type: "BUILDING",
        faction: "ROMANS",
        ...b,
      }));

      charactersData = rawCharactersData["ROMANS"].map((c) => ({
        type: "CHARACTER",
        faction: "ROMANS",
        ...c,
      }));

      resourcesData = rawResourcesData["ROMANS"].map((r) => ({
        type: "RESOURCE",
        faction: "ROMANS",
        ...r,
      }));
      break;
    }
    case "EGYPTIANS": {
      buildingsData = rawBuildingsData["EGYPTIANS"].map((b) => ({
        type: "BUILDING",
        faction: "EGYPTIANS",
        ...b,
      }));

      charactersData = rawCharactersData["EGYPTIANS"].map((c) => ({
        type: "CHARACTER",
        faction: "EGYPTIANS",
        ...c,
      }));

      resourcesData = rawResourcesData["EGYPTIANS"].map((r) => ({
        type: "RESOURCE",
        faction: "EGYPTIANS",
        ...r,
      }));
      break;
    }
    case "ASIANS": {
      buildingsData = rawBuildingsData["ASIANS"].map((b) => ({
        type: "BUILDING",
        faction: "ASIANS",
        ...b,
      }));

      charactersData = rawCharactersData["ASIANS"].map((c) => ({
        type: "CHARACTER",
        faction: "ASIANS",
        ...c,
      }));

      resourcesData = rawResourcesData["ASIANS"].map((r) => ({
        type: "RESOURCE",
        faction: "ASIANS",
        ...r,
      }));
      break;
    }
    case "AMAZONS": {
      buildingsData = rawBuildingsData["AMAZONS"].map((b) => ({
        type: "BUILDING",
        faction: "AMAZONS",
        ...b,
      }));

      charactersData = rawCharactersData["AMAZONS"].map((c) => ({
        type: "CHARACTER",
        faction: "AMAZONS",
        ...c,
      }));

      resourcesData = rawResourcesData["AMAZONS"].map((r) => ({
        type: "RESOURCE",
        faction: "AMAZONS",
        ...r,
      }));
      break;
    }
  }

  return {
    buildingsData,
    charactersData,
    resourcesData,
    otherData,
  };
};
