import type { InitialCardPositionLayerProps } from "../../../lib/interfaces";

export const layersRegularX: InitialCardPositionLayerProps = {
  BUILDING_THE_VENTURER: 0,
  BUILDING_WELL: 0,

  RESOURCE_WATER: 1,

  BUILDING_FARMLAND: 2,

  BUILDING_FORESTER: 2,
  RESOURCE_HARDWOOD: 3,
  RESOURCE_SOFTWOOD: 3,
  BUILDING_WOODCUTTING_HUT: 4,
  RESOURCE_HARDWOOD_TRUNK: 5,
  RESOURCE_SOFTWOOD_TRUNK: 5,
  BUILDING_SAWMILL: 6,
  RESOURCE_HARDWOOD_BOARD: 7,
  RESOURCE_SOFTWOOD_BEAM: 7,
  RESOURCE_FIREWOOD: 7,

  BUILDING_FORAGERS_HUT: 4,
  BUILDING_CROP_FARM: 4,
  RESOURCE_FLAX: 5,
  RESOURCE_WHEAT: 5,

  BUILDING_WINDMILL: 6,
  RESOURCE_FLOUR: 7,
  BUILDING_BAKERY: 8,

  BUILDING_VEGETABLE_FARM: 8,

  BUILDING_HUNTING_LODGE: 6,
  RESOURCE_LEATHER: 7,

  RESOURCE_BREAD: 9,
  RESOURCE_CORN: 9,
  RESOURCE_PUMPKIN: 9,
  RESOURCE_CABBAGE: 9,
  RESOURCE_RASPBERRIES: 9,
  RESOURCE_PORCINI: 9,
  RESOURCE_RABBIT_MEAT: 9,
  RESOURCE_BOAR_MEAT: 9,
  RESOURCE_DEER_MEAT: 9,

  BUILDING_PROVISIONER: 10,
  RESOURCE_RATION: 12,
  BUILDING_EXPLORERS_HUT: 13,

  BUILDING_MARKET_STALL: 10,
  BUILDING_MARKET_FOUNTAIN: 10,
  BUILDING_TAVERN: 11,
  RESOURCE_COMFORT_MEAL: 12,
  RESOURCE_NUTRITIOUS_MEAL: 12,
  BUILDING_TAVERN_TABLE: 13,
  BUILDING_COTTAGE: 14,
  BUILDING_RESIDENCE: 14,
  CHARACTER_CARRIER: 15,

  BUILDING_COPPER_HUT: 13,
  RESOURCE_COPPER: 14,

  BUILDING_COAL_HUT: 13,
  BUILDING_IRON_HUT: 13,
  BUILDING_SILVER_HUT: 13,
  RESOURCE_COAL: 14,
  RESOURCE_IRON_ORE: 14,
  RESOURCE_SILVER_ORE: 14,

  BUILDING_SMELTING_WORKS: 15,

  RESOURCE_IRON_BAR: 16,
  RESOURCE_SILVER_BAR: 16,

  BUILDING_WEAVING_SHED: 17,

  RESOURCE_ROPE: 18,
  RESOURCE_CLOTH: 18,
  RESOURCE_ROBE: 18,
  RESOURCE_SILVERLINED_ROBE: 18,

  BUILDING_TOOL_SMITH: 19,
  BUILDING_WOOD_WORKSHOP: 19,
  BUILDING_WEAPONSMITH: 19,
  BUILDING_ARMORSMITH: 19,

  BUILDING_TAILORS_SHOP: 19,
  RESOURCE_LIGHT_ARMOR: 20,
  RESOURCE_PADDED_ARMOR: 20,

  RESOURCE_AXE: 20,
  RESOURCE_BACKPACK: 20,
  RESOURCE_CHISEL: 20,
  RESOURCE_HAMMER: 20,
  RESOURCE_KNIFE: 20,
  RESOURCE_PICKAXE: 20,
  RESOURCE_PLIERS: 20,
  RESOURCE_SAW: 20,
  RESOURCE_SHOVEL: 20,
  RESOURCE_SICKLE: 20,
  RESOURCE_HUNTING_BOW: 20,

  BUILDING_GUILD_HALL: 21,

  CHARACTER_DIGGER: 22,
  CHARACTER_BUILDER: 22,
  CHARACTER_CONSTRUCTION_SITE_CARRIER: 22,
  CHARACTER_TRADE_CARRIER: 22,

  BUILDING_CONSTRUCTION_CAMP: 23,
  BUILDING_TRADING_POST: 23,
  BUILDING_TRADERS_CAMP: 23,

  RESOURCE_WOODEN_COGWHEEL: 20,
  RESOURCE_TORCH: 20,

  RESOURCE_WOODEN_SPEAR: 20,
  RESOURCE_WOODEN_SHIELD: 20,
  RESOURCE_SILVER_BLADE: 20,
  RESOURCE_BRONZE_SWORD: 20,
  RESOURCE_IRON_SWORD: 20,
  RESOURCE_IRON_LANCE: 20,
  RESOURCE_IRON_SHIELD: 20,
  RESOURCE_CHAIN_MAIL: 20,

  BUILDING_MILITARY_ACADEMY: 21,
  CHARACTER_GUARD: 22,
  CHARACTER_VETERAN_GUARD: 22,
  CHARACTER_SOLDIER: 22,
  CHARACTER_ADVANCED_SOLDIER: 22,
  CHARACTER_VETERAN_SOLDIER: 22,

  BUILDING_MINT: 19,
  RESOURCE_COPPER_COINS: 20,
  RESOURCE_SILVER_COINS: 20,
  RESOURCE_DIAMOND: 20,
  RESOURCE_EMERALD: 20,
  RESOURCE_RUBY: 20,
  RESOURCE_SINGING_BLADE: 20,
  RESOURCE_ASHWOOD_AEGIS: 20,
  RESOURCE_SICKLE_OF_ABUNDANCE: 20,
  RESOURCE_ANCIENT_COMPASS: 20,
  RESOURCE_GOLDEN_HORSE: 20,
  RESOURCE_WITCHWOOD_HARP: 20,

  BUILDING_TREASURY: 21,

  BUILDING_ARTISAN_BLACKSMITH: 21,

  RESOURCE_MAGIC_TORCH: 22,
  RESOURCE_MAGIC_BLADE: 22,

  BUILDING_ADVENTURERS_GUILD: 23,
  CHARACTER_RANGER: 24,
  CHARACTER_SKILLED_RANGER: 24,
  CHARACTER_FEARNAUGHT: 24,
  CHARACTER_HEROIC_FEARNAUGHT: 24,

  BUILDING_QUARRY: 13,
  RESOURCE_STONE: 14,
  RESOURCE_LIMESTONE: 14,
  BUILDING_STONE_MASON: 15,
  BUILDING_STONE_MILL: 15,
  RESOURCE_BORDER_STONE: 16,
  RESOURCE_CUT_STONE: 16,
  RESOURCE_CEMENT: 16,
  BUILDING_GUARD_TOWER: 25,
  BUILDING_GARRISON: 25,
  BUILDING_MILITARY_CAMP: 25,

  BUILDING_STORAGE: 0,
};
