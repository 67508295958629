import type {
  CustomEdgeDataProps,
  CustomNodeDataProps,
} from "@/lib/interfaces";
import type { Edge, Node } from "reactflow";
import { getViewDependentData } from "./data";
import { getStaticNodePositions } from "./nodePositions";

export const getNodesAndEdges = (view?: string) => {
  const staticNodePositions = getStaticNodePositions(view);
  const { buildingsData, charactersData, resourcesData } =
    getViewDependentData(view);

  const staticNodes: Node<CustomNodeDataProps>[] = [];
  const staticEdges: Edge<CustomEdgeDataProps>[] = [];

  switch (view) {
    default: {
      [...buildingsData, ...charactersData, ...resourcesData].forEach(
        (element) => {
          staticNodes.push({
            id: `${element.type}-${element.name}`,
            position: {
              x: staticNodePositions[`${element.type}_${element.name}`]?.x ?? 0,
              y: staticNodePositions[`${element.type}_${element.name}`]?.y ?? 0,
            },
            data: element,
            type: "pcfNode",
          });
        },
      );

      [...buildingsData].forEach((element) => {
        // in
        element.requires?.forEach((x) => {
          staticEdges.push({
            id: `BUILDING-${x}-${element.type}-${element.name}`,
            source: `BUILDING-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });

        element.production?.forEach((production) =>
          production.utilizes.forEach((utilElem) =>
            utilElem.forEach((util) => {
              const newId = `RESOURCE-${util.type}-${element.type}-${element.name}`;
              if (!staticEdges.some((e) => e.id === newId))
                staticEdges.push({
                  id: newId,
                  source: `RESOURCE-${util.type}`,
                  target: `${element.type}-${element.name}`,
                });
            }),
          ),
        );

        // out
        element.provides?.forEach((x) => {
          staticEdges.push({
            id: `${element.type}-${element.name}-RESOURCE-${x}`,
            source: `${element.type}-${element.name}`,
            target: `RESOURCE-${x}`,
          });
        });
        element.production?.forEach((production) => {
          production.produces.forEach((prod) => {
            const newId = `${element.type}-${element.name}-RESOURCE-${prod.type}`;
            if (!staticEdges.some((e) => e.id === newId))
              staticEdges.push({
                id: `${element.type}-${element.name}-RESOURCE-${prod.type}`,
                source: `${element.type}-${element.name}`,
                target: `RESOURCE-${prod.type}`,
              });
          });
        });
      });

      [...charactersData].forEach((element) => {
        // in
        element.production?.forEach((production) =>
          production.utilizes.forEach((utilElem) =>
            utilElem.forEach((util) => {
              const newId = `RESOURCE-${util.type}-${element.type}-${element.name}`;
              if (!staticEdges.some((e) => e.id === newId))
                staticEdges.push({
                  id: newId,
                  source: `RESOURCE-${util.type}`,
                  target: `${element.type}-${element.name}`,
                });
            }),
          ),
        );

        // out
        element.uses?.forEach((x) => {
          staticEdges.push({
            id: `RESOURCE-${x}-${element.type}-${element.name}`,
            source: `RESOURCE-${x}`,
            target: `${element.type}-${element.name}`,
          });
        });
      });

      break;
    }
  }

  return { staticNodes, staticEdges };
};
