import AgainstTheStormData from "@/app/against-the-storm/constants";
import PioneersOfPagoniaData from "@/app/pioneers-of-pagonia/constants";
import TheSettlers2Data from "@/app/the-settlers-2/constants";
import TheSettlers3Data from "@/app/the-settlers-3/constants";
import TheSettlers4Data from "@/app/the-settlers-4/constants";
import TheSettlersData from "@/app/the-settlers/constants";

export const getSelectedGameData = (game: string) => {
  switch (game) {
    default:
    case "pioneers-of-pagonia":
      return { ...PioneersOfPagoniaData };
    case "the-settlers":
      return { ...TheSettlersData };
    case "the-settlers-2":
      return { ...TheSettlers2Data };
    case "the-settlers-3":
      return { ...TheSettlers3Data };
    case "the-settlers-4":
      return { ...TheSettlers4Data };
    case "against-the-storm":
      return { ...AgainstTheStormData };
  }
};

interface SUPPORTED_GAME_PROPS {
  game: string;
  title: string;
  disabled?: boolean;
}

export const SUPPORTED_GAMES: SUPPORTED_GAME_PROPS[] = [
  {
    game: "against-the-storm",
    title: "Against the Storm",
  },
  {
    game: "pioneers-of-pagonia",
    title: "Pioneers of Pagonia",
  },
  {
    game: "the-settlers",
    title: "The Settlers",
  },
  {
    game: "the-settlers-2",
    title: "The Settlers 2",
  },
  {
    game: "the-settlers-3",
    title: "The Settlers 3",
  },
  {
    game: "the-settlers-4",
    title: "The Settlers 4",
  },
  {
    game: "suggestions",
    title: "Suggest a game to be added",
  },
];
