import { aoboshiOne } from "@/lib/constants/fonts";
import { Typography } from "@mui/material";
import Link from "next/link";

export const PrivacyPolicyLink = () => {
  return (
    <Link href="/privacy">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        Privacy Policy
      </Typography>
    </Link>
  );
};

export const ImpressumLink = () => {
  return (
    <Link href="/impressum">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        Impressum
      </Typography>
    </Link>
  );
};

export const InfoLink = () => {
  return (
    <Link href="https://maze.codes/projects/pcf" target="_blank">
      <Typography
        sx={{
          "padding": 0.5,
          "fontSize": 12,
          "color": "primary.main",
          "fontFamily": aoboshiOne.style.fontFamily,
          ":hover": {
            color: "text.primary",
          },
        }}
      >
        More Info
      </Typography>
    </Link>
  );
};
