import DE from "../locales/de-DE.json";
import EN from "../locales/en-US.json";
import ES from "../locales/es-ES.json";
import FR from "../locales/fr-FR.json";
import IT from "../locales/it-IT.json";
import JA from "../locales/ja-JP.json";
import NL from "../locales/nl-NL.json";
import PL from "../locales/pl-PL.json";
import PT from "../locales/pt-BR.json";

export const getDictionary = (locale: string) => {
  switch (locale) {
    case "en":
    default:
      return EN;
    case "de":
      return DE;
    case "fr":
      return FR;
    case "es":
      return ES;
    case "pl":
      return PL;
    case "pt":
      return PT;
    case "it":
      return IT;
    case "nl":
      return NL;
    case "ja":
      return JA;
  }
};

export const languages = [
  "en-US",
  "de-DE",
  "fr-FR",
  "es-ES",
  "pt-BR",
  "pl-PL",
  "it-IT",
  "nl-NL",
  "ja-JP",

  "cs-CZ",
  "ko-KR",
  "sk-SK",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "zh-CN",
  "zh-HK",
];
// "tlh-TLH",

export const enabledLanguages = [
  "en-US",
  "de-DE",
  "fr-FR",
  "es-ES",
  "pt-BR",
  "pl-PL",
  "it-IT",
  "nl-NL",
  "ja-JP",
];
